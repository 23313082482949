import {
  ContextualMenu,
  ContextualMenuItemType,
  IContextualMenuItem,
} from "@fluentui/react/lib/ContextualMenu";
import React, { useRef, useState } from "react";
import UsersStore from "stores/users/usersStore";
import { Stack, Text } from "@fluentui/react";
import { getStyles } from "./userProfileMenu.styleNames";
import { b2cPolicies } from "components/auth/policies";
import AuthRedirect from "components/auth/AuthRedirect";
import { useMsal } from "@azure/msal-react";
import Stores from "stores";
import { inject } from "mobx-react";
export interface IUserProfileProps {
  usersStore?: UsersStore;
}

const UserProfileMenu = inject(Stores.UsersStore)(
  (props: IUserProfileProps) => {
    const {
      calloutWrapper,
      userProfileButtonWrapper,
      userProfileButtonTextStyle,
    } = getStyles();
    const userProfileButton = useRef<HTMLDivElement>(null);
    const [collapsed, setCollapsed] = useState<boolean>(true);
    const { instance } = useMsal();
    const authRedirect = new AuthRedirect(instance);
    const menuItems: IContextualMenuItem[] = [
      {
        key: "logout",
        text: "Logout",
        href: "/logout",
        iconProps: { iconName: "Leave" },
      },
    ];
    if (props.usersStore?.currentUser.isLocal) {
      menuItems.unshift(
        {
          key: "changeEmail",
          text: "Change Email",
          onClick: () => {
            authRedirect.RedirectTo(b2cPolicies.authorities.changeEmail);
          },
          iconProps: { iconName: "EditMail" },
        },
        {
          key: "changePassword",
          text: "Change Password",
          iconProps: { iconName: "Lock" },
          onClick: () => {
            authRedirect.RedirectTo(b2cPolicies.authorities.changePassword);
          },
        },
        {
          key: "divider_1",
          itemType: ContextualMenuItemType.Divider,
        }
      );
    }

    const getUserLogoName = (name?: string): string => {
      let initials = name && name.length > 0 ? name : "UN";
      if (initials) {
        const arrInitials = [...initials];
        const arrResult = new Array<string>();
        arrInitials.forEach((l: string) => {
          if (l === l.toUpperCase()) arrResult.push(l);
        });
        if (arrResult.length > 0)
          initials = `${arrResult.join("").replace(/\s*,\s*/g, "")}`.substring(
            0,
            2
          );
      }

      return initials;
    };
    const onHideUserProfile = React.useCallback(() => setCollapsed(true), []);
    const toggleUserProfile = React.useCallback(
      (ev: React.MouseEvent<HTMLElement>) => {
        ev.preventDefault();
        setCollapsed((collapsed) => !collapsed);
      },
      []
    );

    return (
      <div ref={userProfileButton}>
        <Stack styles={userProfileButtonWrapper} onClick={toggleUserProfile}>
          <Text styles={userProfileButtonTextStyle}>
            {getUserLogoName(props.usersStore?.currentUser.displayName)}
          </Text>
          <Stack>
            <ContextualMenu
              items={menuItems}
              hidden={collapsed}
              target={userProfileButton}
              calloutProps={{ styles: calloutWrapper }}
              onItemClick={onHideUserProfile}
              onDismiss={onHideUserProfile}
            />
          </Stack>
        </Stack>
      </div>
    );
  }
);

export default UserProfileMenu;
