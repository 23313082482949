import { Spinner, SpinnerSize, Stack } from "@fluentui/react";
import { getStyleNames } from "./loading.styleNames";

const { loadingWrapper, spinner } = getStyleNames();

const Loading = () => (
  <Stack styles={loadingWrapper}>
    <Spinner size={SpinnerSize.large} styles={spinner} />
  </Stack>
);

export default Loading;
