import { matchPath, generatePath } from "react-router";
import { action, observable, makeObservable } from "mobx";
import { IAppRouterItem } from "types";
import { IAppNavRouterItem } from "types/routing/IAppNavRouterItem";
import { authUtils, strings } from "utils";
import { appRouter, employeeRouter } from "configs/routing";
class RouterStore {
  @observable.deep currentRouter!: IAppRouterItem;
  @observable currentFocusableMenu: string = "";

  public readonly defaultPath = "/";
  public readonly defaultRedirectPath = "/:id/dashboard";
  private readonly allRouters: IAppRouterItem[];
  private navRouters: IAppNavRouterItem[];
  private overflowRouters: IAppNavRouterItem[];

  constructor() {
    this.allRouters = this.getRouters();
    this.navRouters = [];
    this.overflowRouters = [];
    makeObservable(this);
  }

  get NavRouters(): IAppNavRouterItem[] {
    return this.navRouters;
  }

  get OverflowRouters(): IAppNavRouterItem[] {
    return this.overflowRouters;
  }

  @action
  setRedirectLocationPath(pathname: string) {
    localStorage.setItem(
      strings.redirectUrlLocalKey,
      encodeURIComponent(pathname)
    );
  }

  @action
  isLocationAvailable(roles: Array<string>): boolean {
    return (
      !this.currentRouter.permission ||
      authUtils.isInRole(this.currentRouter.permission, roles!)
    );
  }

  @action
  getRedirectLocationPath(): string | null {
    const redirectUrl = localStorage.getItem(strings.redirectUrlLocalKey);
    if (!redirectUrl) return null;

    return decodeURIComponent(redirectUrl);
  }

  @action
  initRouters() {
    this.currentRouter = this.getRouter(this.defaultPath);
    this.navRouters = this.getDefaultRouters();
    this.overflowRouters = this.getDefaultRouters();
  }

  @action
  updateCurrentRouter = (router: IAppRouterItem) => {
    this.currentRouter = router;
  };

  @action
  updateRouters = (router: IAppRouterItem) => {
    this.navRouters = this.getDefaultRouters();
    this.overflowRouters = this.getDefaultRouters();
    this.updateCurrentRouter(router);
    this.updateNavRouters(this.navRouters, router);
    this.updateOverflowRouters(this.overflowRouters, router);
  };

  @action
  updateNavRouters = (
    routers: IAppNavRouterItem[],
    currentRouter: IAppRouterItem
  ) => {
    if (!currentRouter) return;
    if (!currentRouter.parent) return;
    const parentRouter = this.allRouters.find(
      (route) => route.key === currentRouter.parent
    );
    if (parentRouter && currentRouter.isLoadableByRequest) {
      const parentRouterIndex = routers.findIndex(
        (route) => route.router.key === parentRouter.key
      );
      const navRouter = {
        router: currentRouter,
        items: this.getNavRouterItems(currentRouter).sort(
          (k1, k2) => Number(k1.router.key) - Number(k2.router.key)
        ),
      } as IAppNavRouterItem;
      if (parentRouterIndex >= 0) {
        routers.splice(parentRouterIndex + 1, 0, navRouter);
      } else {
        this.updateNavRouters(routers, parentRouter);
        this.insertNavRouterItem(routers, navRouter);
      }
    } else {
      this.updateNavRouters(routers, parentRouter!);
    }
  };

  @action
  updateOverflowRouters = (
    routers: IAppNavRouterItem[],
    currentRouter: IAppRouterItem
  ) => {
    if (!currentRouter) return;
    if (!currentRouter.parent) return;
    const parentRouter = this.allRouters.find(
      (route) => route.key === currentRouter.parent
    );
    if (parentRouter && currentRouter.isLoadableByRequest) {
      const parentRouterIndex = routers.findIndex(
        (route) => route.router.key === parentRouter.key
      );
      const overflowRouter = {
        router: currentRouter,
        items: this.getNavRouterItems(currentRouter).sort(
          (k1, k2) => Number(k2.router.key) - Number(k1.router.key)
        ),
      } as IAppNavRouterItem;

      if (parentRouterIndex >= 0) {
        overflowRouter.items.forEach((item: IAppNavRouterItem) => {
          routers.splice(parentRouterIndex + 1, 0, item);
        });
      } else {
        this.updateOverflowRouters(routers, parentRouter);
        this.insertNavRouterItem(routers, overflowRouter);
      }
    } else {
      this.updateOverflowRouters(routers, parentRouter!);
    }
  };

  @action
  getAllRouters() {
    return this.allRouters;
  }

  @action
  getPageTitle = (pathname: string): string => {
    const route = this.getRouter(pathname);
    const titleFormat = `{0} | ${strings.appLabel}`;
    if (!route) return strings.appLabel;
    if (route.pageTitle) return titleFormat.replace("{0}", route.pageTitle);
    if (route.overTitle) return titleFormat.replace("{0}", route.overTitle);

    return titleFormat.replace("{0}", route.title);
  };

  @action
  getRouter = (path: string): any => {
    return this.allRouters.find((route) => {
      const match = this.getMatch(path, route.path);
      return match !== null;
    });
  };

  @action
  getMatch = (locationPath: string, routePath: string): any => {
    return matchPath(routePath, locationPath);
  };

  @action
  getRouterByParent = (parent?: string, excludeEmbed: boolean = true): any => {
    if (!parent) return null;
    const router = this.allRouters.find((route) => route.key === parent);
    if (router && excludeEmbed === true && router.isEmbed === true) {
      return this.allRouters.find((route) => route.key === router.parent);
    }
    return router;
  };

  @action
  setCurrentFocusableMenu = (focusableSelector: string) => {
    this.currentFocusableMenu = focusableSelector;
  };

  @action
  getRouterPathByScreen = (
    screen: string,
    screenParamValues: Array<string>
  ): string | undefined => {
    const router = this.allRouters.find((route: IAppRouterItem) =>
      screen.includes(route.screen!)
    );
    if (!router) return undefined;
    let pathParams = undefined;
    if (router.screenParams) {
      pathParams = { [router.screenParams[0]]: screenParamValues[0] };
      if (router.screenParams.length > 1)
        pathParams[router.screenParams[1]] = screenParamValues[1];
    }
    let routerPath = generatePath(router.path, pathParams);
    return routerPath;
  };

  @action
  getMatchParams = (locationPath: string): any => {
    const router = this.allRouters.find((route) => {
      const match = this.getMatch(locationPath, route.path);
      return match !== null;
    });
    let matchObject = undefined;
    if (router) matchObject = this.getMatch(locationPath, router.path);
    return matchObject ? matchObject.params : undefined;
  };

  private getRouters() {
    return [...appRouter, ...employeeRouter].sort(
      (k1, k2) => k2.path.length - k1.path.length
    );
  }

  private getDefaultRouters() {
    return [...appRouter, ...employeeRouter]
      .filter((route) => route.showInMenu)
      .sort((k1, k2) => Number(k1.key) - Number(k2.key))
      .map((route) => {
        return {
          router: route,
          items: [],
        } as IAppNavRouterItem;
      });
  }

  private getNavRouterItems(router: any): IAppNavRouterItem[] {
    let items: IAppNavRouterItem[] = [];
    this.allRouters
      .filter(
        (routerItem: any) =>
          routerItem.parent === router.key &&
          routerItem.isPage &&
          routerItem.store === router.store
      )
      .forEach((route: any) => {
        const navRouterItem = this.getNavRouterItem(route);
        if (navRouterItem) items.push(navRouterItem);
      });
    return items;
  }

  private getNavRouterItem(route: any): any {
    const items: IAppNavRouterItem[] = [];
    this.allRouters
      .filter(
        (routerItem: any) =>
          routerItem.parent === route.key && routerItem.store === route.store
      )
      .forEach((route: any) => {
        items.push(this.getNavRouterItem(route));
      });

    return {
      router: route,
      items: items.sort(
        (k1, k2) => Number(k1.router.key) - Number(k2.router.key)
      ),
    };
  }

  private insertNavRouterItem(
    navRouters: IAppNavRouterItem[],
    navRouterItem: IAppNavRouterItem
  ) {
    if (!navRouters) return;
    const routerKey = navRouterItem.router.loadAfterKey
      ? navRouterItem.router.loadAfterKey
      : navRouterItem.router.parent;
    const navRouter = navRouters.find(
      (item: IAppNavRouterItem) => item.router.key === routerKey
    );
    if (navRouter) {
      const itemIndex = navRouters.findIndex(
        (item: any) => item.router.key === navRouter.router.key
      );
      const itemData = this.getNavRouterItem(navRouterItem.router);
      navRouters.splice(itemIndex + 1, 0, itemData);
    } else {
      let router = navRouters.find(
        (item: IAppNavRouterItem) =>
          item.router.key === navRouterItem.router.parent &&
          item.items.length > 0
      );
      if (!router) {
        router = navRouters.find(
          (item: IAppNavRouterItem) => item.items.length > 0
        );
      }
      this.insertNavRouterItem(router?.items!, navRouterItem);
    }
  }
}

export default RouterStore;
