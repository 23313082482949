import { ITextStyles, IStackItemProps, IStackItemStyles, IStackProps, IStackStyles, getTheme, } from "@fluentui/react";

const theme = getTheme();

export interface IUnauthorizedStyleNames {
  errorMessageText: ITextStyles;
}

export function getErrorMessageBeakStyles(props: IStackProps): Partial<IStackStyles> {
  return {
    root: {
      position: "relative",
      width: 7.82,
      height: 7.82,
      left: 9.54,
      top: 1.5,
      background: "#EF273C",
      borderRadius: 1,
      transform: "matrix(0.71, 0.71, -0.71, 0.71, 0, 0)"
    },
  }
}

export function getErrorMessageStyles(props: IStackItemProps): Partial<IStackItemStyles> {
  return {
    root: {
      boxShadow: "0px 4px 8px rgba(84, 110, 122, 0.24)",
      borderRadius: "3px",
      backgroundColor: '#EF273C',
      display: "inline-flex",
      marginTop: -4,
      padding: "0 6px 0 6px",
      minHeight: 24,
      alignItems: "center",
      verticalAlign: "center",
      color: theme.palette.white,
      fontSize: 12,
      lineHeight: 16,
      selectors: {
        '& i': {
          display: "flex",
          height: 12,
          marginRight: 6,
          width: 12,
          zIndex: 2
        },
        '& span': {
          letterSpacing: "0.4px",
        },
      },
    },
  };
}
