import {
  IStackStyles,
  IStackItemStyles,
  IDropdownStyles,
  ITextStyles,
  IButtonStyles,
} from "@fluentui/react";

export interface IPagingStyleNames {
  pagingWrapper: IStackStyles;
  firstPageWrapper: IStackItemStyles;
  lastPageWrapper: IStackItemStyles;
  prevPageWrapper: IStackItemStyles;
  nextPageWrapper: IStackItemStyles;
  dropdownPage: Partial<IDropdownStyles>;
  pageCountWrapper: IStackItemStyles;
  textPageCount: Partial<ITextStyles>;
  iconButton: IButtonStyles;
  resultWrapper: IStackItemStyles;
  textResult: Partial<ITextStyles>;
  textShow: Partial<ITextStyles>;
}

export const getStyleNames = (): IPagingStyleNames => ({
  pagingWrapper: {
    root: {
      paddingTop: 10,
    },
  },
  firstPageWrapper: {
    root: {
      alignSelf: "center",
      paddingRight: 20,
      paddingLeft: 21,
    },
  },
  lastPageWrapper: {
    root: {
      alignSelf: "center",
      paddingLeft: 20,
    },
  },
  prevPageWrapper: {
    root: {
      alignSelf: "center",
      paddingRight: 12,
    },
  },
  nextPageWrapper: {
    root: {
      alignSelf: "center",
      paddingLeft: 12,
    },
  },
  dropdownPage: {
    root: {
      width: 70,
      height: 32,
    },
    title: {
      borderColor: "#B3B0AD",
      borderRadius: 2,
      boxSizing: "border-box",
    },
  },
  pageCountWrapper: {
    root: {
      alignSelf: "baseline",
      paddingLeft: 8,
      paddingTop: 5,
    },
  },
  textPageCount: {
    root: {
      color: "#323130",
    },
  },
  iconButton: {
    root: {
      backgroundColor: "transparent",
      color: "#605E5C",
    },
    rootDisabled: {
      backgroundColor: "transparent",
      opacity: "0.4",
    },
    rootHovered: {
      backgroundColor: "#F3F2F1",
      borderRadius: 2,
    },
    rootFocused: {
      border: "1px solid #8A8886",
      boxSizing: "border-box",
    },
    rootPressed: {
      backgroundColor: "#EDEBE9",
      borderRadius: 2,
    },
  },
  resultWrapper: {
    root: {
      paddingTop: 5,
      alignSelf: "baseline",
    },
  },
  textResult: {
    root: {
      fontWeight: "500",
      color: "#A7A7A7",
    },
  },
  textShow: {
    root: {
      paddingLeft: 12,
      paddingRight: 6,
      fontWeight: "500",
      color: "#636363",
    },
  },
});
