import React, { useState } from "react";
import { Stack, Callout, DirectionalHint } from "@fluentui/react";
import { getRowMenuGridStyleNames } from "./rowMenu.styleNames";
import { iconNames } from "../../utils";
import { IRowMenuItem } from "../../types";
import { getOnsiGridStyleNames } from "../../styles/onsiGrid.styleNames";
import RowMenuBuilder from "./rowMenuBuilder";
import componentHelper from "components/componentHelper";

export interface IRowMenuGridProps {
  id: string;
  rowMenuItems: Array<IRowMenuItem>;
  calloutStyles?: any;
  containerStyles?: any;
  internalContainerStyles?: any;
  isSubmenu?: boolean;
}

export const RowMenuGrid: React.FC<IRowMenuGridProps> = (props) => {
  const [isRowMenuVisible, setRowMenuVisible] = useState(false);
  const [gridMoreIconName, setGridMoreIconName] = useState(iconNames.gridMore);
  const [isGridMoreVisible, setGridMoreVisible] = useState(true);
  const onRowMenuDismiss = () => {
    setRowMenuVisible(false);
    setGridMoreIconName(iconNames.gridMore);
    setGridMoreVisible(true);
  };
  const onShowRowMenu = () => {
    setRowMenuVisible(true);
    setGridMoreIconName(iconNames.gridMoreExpand);
    setGridMoreVisible(false);
  };
  const { stackStyles, iconGridMore, iconGridHover, callout } =
    getRowMenuGridStyleNames();
  const gridMoreStyles =
    gridMoreIconName === iconNames.gridMore ? iconGridMore : iconGridHover;
  const rowMenuPrefix = props.isSubmenu ? "row-submenu-id" : "row-menu-id";
  const rowMenuId = `${rowMenuPrefix}-${props.id}`;
  const rowMenuBuilder = new RowMenuBuilder(props.id);
  const { rowMenuList, rowMenuListItem } = getOnsiGridStyleNames();

  return (
    <Stack>
      <div className={rowMenuId}>
        <Stack styles={props.containerStyles}>
          {componentHelper.renderRowMenuItem(
            gridMoreIconName,
            gridMoreStyles,
            isGridMoreVisible
              ? () => onShowRowMenu()
              : () => onRowMenuDismiss(),
            props.id + gridMoreIconName
          )}
        </Stack>
      </div>
      {isRowMenuVisible ? (
        <Callout
          target={`.${rowMenuId}`}
          isBeakVisible={false}
          onDismiss={onRowMenuDismiss}
          directionalHint={DirectionalHint.leftCenter}
          setInitialFocus={true}
          backgroundColor="transparent"
          styles={props.calloutStyles ? props.calloutStyles : callout}
        >
          <Stack
            horizontal
            styles={
              props.internalContainerStyles
                ? props.internalContainerStyles
                : stackStyles
            }
          >
            <ul style={rowMenuList}>
              {props.rowMenuItems.map((menuItem: IRowMenuItem): JSX.Element => {
                const itemKey = menuItem.key || props.id + menuItem.iconName;
                return (
                  <li key={itemKey} style={rowMenuListItem}>
                    {rowMenuBuilder.getRenderedRowMenuItem(menuItem)}
                  </li>
                );
              })}
            </ul>
          </Stack>
        </Callout>
      ) : null}
    </Stack>
  );
};
