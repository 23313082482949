import { ISearchBoxStyles, IButtonStyles } from "@fluentui/react";

export interface ISearchFilterStyleNames {
  searchbox: Partial<ISearchBoxStyles>;
  clearButton: Partial<IButtonStyles>;
}

const textColor = "#A9A9A9";
const clearButtonStyles = {
  background: "auto",
  backgroundColor: "#FFFFFF",
  marginRight: 2,
  height: 34,
  width: 34,
};

export const getSearchFilterStyleNames = (): ISearchFilterStyleNames => ({
  searchbox: {
    root: {
      width: 406,
      height: 36,
      border: "1px solid #A7A7A7",
    },
    field: {
      color: "#636363",
      selectors: {
        "::placeholder": {
          color: textColor,
        },
        ":-ms-input-placeholder": {
          color: textColor,
        },
        "::-ms-input-placeholder": {
          color: textColor,
        },
      },
    },
  },
  clearButton: {
    root: {
      selectors: {
        svg: clearButtonStyles,
        "svg:hover": clearButtonStyles,
      },
    },
    flexContainer: {
      alignItems: "baseline",
    },
  },
});
