import { ITooltippedTextFieldStyles } from ".";
import { getColorStyleNames } from "../../styles/color.styleNames";
import { getMainPalette } from "../../styles/mainPalette";

const defaultPalette = getMainPalette();
const { calloutBackgroundColor } = getColorStyleNames();

export const getTooltippedTextFieldStyles = (
  hasError?: boolean
): ITooltippedTextFieldStyles => ({
  tooltipText: { root: { color: defaultPalette.white } },
  tooltipHost: { root: { display: "inline-block" } },
  tooltipCallout: {
    beak: {
      backgroundColor: calloutBackgroundColor,
    },
    beakCurtain: {
      backgroundColor: calloutBackgroundColor,
    },
  },
});
