import { action, makeObservable } from "mobx";
import employeeService from "services/employees/employeeService";

import { IAssignEmployeeRoleItem, IEmployeeListItem } from "types";

export default class EmployeeStore {
  employeesList: IEmployeeListItem[] = [];

  constructor() {
    makeObservable(this);
  }

  @action
  async getEmployeeList(companyId: string) {
    const result = await employeeService.getEmployees(companyId);
    if (result) this.employeesList = result;
  }

  @action
  async resetStoreMembers() {
    this.employeesList = [];
  }

  @action
  getStoreDisplayName(
    employee?: any,
    includeNumber?: boolean
  ): string | undefined {
    if (!employee) return undefined;
    let emplId = employee.eeid;
    if (!emplId) emplId = employee.eeId;
    const extraText = includeNumber ? ` #${emplId}` : "";
    return `${employee.lastName}, ${employee.firstName} ${extraText}`;
  }

  @action
  async assignEmployeeRoles(
    companyId: string,
    model: IAssignEmployeeRoleItem
  ): Promise<any> {   
      const result = await employeeService.assignEmployeeRoles(companyId, model);
      return Promise.resolve(result);   
  }
}
