import axios, { AxiosInstance } from "axios";
import moment from "moment";
import { toast } from "react-toastify";
import TokenService from "services/systems/tokenService";

const qs = require("qs");

export class HttpService {
  private static instance: HttpService;
  private static axiosInstance: AxiosInstance;
  private static navigate: any;

  private constructor() {}

  private static createAxiosInstance(): AxiosInstance {
    let http = axios.create({
      baseURL: process.env.REACT_APP_REMOTE_SERVICE_BASE_URL,
      timeout: 30000,
      paramsSerializer: {
        serialize: function (params: any) {
          return qs.stringify(params, {
            encode: false,
          });
        },
      },
      headers: {
        "Content-Type": "application/json",
      },
      transformRequest: [
        function (data: any) {
          /*eslint no-extend-native: ["error", { "exceptions": ["Date"] }]*/
          Date.prototype.toJSON = function () {
            return moment(this).format();
          };
          data = JSON.stringify(data);
          return data;
        },
      ],
    });
    http.interceptors.request.use(
      async function (config: any) {
        var authResult = await TokenService.getUserInformation();
        config.headers["Authorization"] = "Bearer " + authResult.accessToken;
        return config;
      },
      function (error: any) {
        return Promise.reject(error);
      }
    );

    http.interceptors.response.use(
      (response: any) => {
        return response;
      },
      (error: any) => {
        if (!HttpService.navigate) {
          console.warn("History is not initialized");
        }
        console.error(error.response || error);
        if (error.message === "Network Error" && !error.response) {
          toast.error("Network Error - Make sure API is running", {
            autoClose: false,
            closeButton: true,
            position: "top-center",
          });
        }
        if (!!error.response) {
          switch (error.response.status) {
            case 401:
            case 403:
            case 404:
              HttpService.navigate.push("/exception/" + error.response.status);
          }
        }
        return Promise.reject(error.response?.data);
      }
    );
    return http;
  }

  public static init(navigate: any) {
    if (!HttpService.instance) {
      HttpService.instance = new HttpService();
    }
    HttpService.navigate = navigate;
    HttpService.axiosInstance = HttpService.createAxiosInstance();
    return HttpService.instance;
  }

  public static getInstance(): AxiosInstance {
    if (!HttpService.instance) {
      console.info(
        "The HttpService is not initialized. Invoke HttpService.init(history) at first."
      );
      HttpService.init(null);
    }
    return HttpService.axiosInstance;
  }
}
export const http = HttpService.getInstance();
