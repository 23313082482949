import {
  ICalloutContentStyles,
  IStackItemStyles,
  ITextStyles,
} from "@fluentui/react";
import { getDefaultFonts } from "styles/fonts/fontDefault";
const { fontRegular } = getDefaultFonts();

export interface IUserProfileStyleNames {
  calloutWrapper: Partial<ICalloutContentStyles>;
  userProfileButtonTextStyle: ITextStyles;
  userProfileButtonWrapper: IStackItemStyles;
}

export const getStyles = (): IUserProfileStyleNames => ({
  calloutWrapper: {
    root: {
      right: "15px !important",
      left: "auto !important",
    },
  },
  userProfileButtonTextStyle: {
    root: {
      alignSelf: "center",
      fontSize: 16,
      fontWeight: 600,
      color: "#FFFFFF",
      padding: 10,
      alignItems: "center",
      fontStyle: "normal",
      fontFamily: fontRegular.fontFamily,
      marginBottom: 5,
    },
  },
  userProfileButtonWrapper: {
    root: {
      width: 36,
      height: 36,
      backgroundColor: "#198bf5",
      display: "flex",
      borderRadius: "50%",
      flexFlow: "column nowrap;",
      cursor: "pointer",
      selectors: {
        "&:hover": {
          color: "white",
          backgroundColor: "#106ebe",
        },
        "&:active": {
          color: "white",
          backgroundColor: "#106ebe",
        },
      },
    },
  },
});
