import { IStackStyles } from "@fluentui/react";

export interface IContentStyle {
  contentWrapper: IStackStyles;
}

export const getStyles = (): IContentStyle => ({
  contentWrapper: {
    root: {
      margin: "0 auto",
      maxWidth: "calc(100vw - 240px)",
      minHeight: "calc(100vh - 112px)",
      "@media(max-width: 600px)": {
        maxWidth: "max-width: calc(100vw - 150px)",
        minHeight: "calc(100vh - 140px)",
      },
    },
  },
});
