export interface IColorStyleNames {
  backgroundColor: string;
  navMenuBackgroundColor: string;
  headerBackgroundColor: string;
  formBackgroundColor: string;
  fieldBackgroundColor: string;
  contentBackgroundColor: string;
  footerBackgroundColor: string;
  calloutBackgroundColor: string;
  textColor: string;
  lightTextColor: string;
  gridCellTextColor: string,
  linkColor: string;
  buttonColor: string;
  focusColor: string;
  disabledButtonColor: string;
  buttonTextColor: string;
  disabledLinkColor: string;
  placeholderColor: string;
  darkSpinnerColor: string;
  auditBackgroundColor: string;
}
export interface IDetailsListColors {
  color: string;
  backgroundColor: string;
  headerColor: string;
  headerBackgroundColor: string;
  borderColor: string;
}

export const getColorStyleNames = (): IColorStyleNames => ({
  backgroundColor: "#fbfbfb",
  navMenuBackgroundColor: "#0D5DBC",
  headerBackgroundColor: "#ffff",
  formBackgroundColor: "#ffff",
  fieldBackgroundColor: "#ffff",
  contentBackgroundColor: "#f2f2f2",
  footerBackgroundColor: "#fbfbfb",
  calloutBackgroundColor: "#263238",
  textColor: "#636363",
  lightTextColor: "#ffff",
  gridCellTextColor: "#2F3C42",
  linkColor: "#198BF5",
  buttonColor: "#198BF5",
  focusColor: "#0078d4",
  disabledButtonColor: "#CACACA",
  buttonTextColor: "#FFFFFF",
  disabledLinkColor: "#A9A9A9",
  placeholderColor: "#A9A9A9",
  darkSpinnerColor: "#000",
  auditBackgroundColor: "#00A341",
});

export const getColorListStyleNames = (): IDetailsListColors => ({
  color: "#0000",
  backgroundColor: "#ffff",
  headerColor: "#636363",
  headerBackgroundColor: "#ECECEC",
  borderColor: "#a7a7a7",
});
