import * as React from "react";
import { Stack, Modal, StackItem } from "@fluentui/react";
import { toast, ToastContainer } from "react-toastify";
import {
  IModalSetupProps,
  IModalSetupState,
  ModalSetup,
} from "components/Model/ModalSetup";
import { IMutableModel } from "components/Model/Model.types";
import { ITechSupportRequest } from "types";
import TechSupportForm from "../TechSupportForm";
import Stores from "stores";
import { inject } from "mobx-react";
import TechSupportStore from "stores/support/techSupportStore";
import { techSupportStrings } from "utils";
import ModalLoading from "components/ModalLoading";
import CompanyStore from "stores/companies/companyStore";

export interface ITechSupportSetupProps extends IModalSetupProps {
  companyStore?: CompanyStore;
  techSupportStore?: TechSupportStore;
}

@inject(Stores.TechSupportStore, Stores.CompanyStore)
export default class TechSupportSetup
  extends ModalSetup<ITechSupportSetupProps, IModalSetupState>
  implements IMutableModel
{
  private techSupportForm = React.createRef<TechSupportForm>();
  private readonly toastContainerId = "TechSupport";
  private readonly fieldDropdownId = "reason";
  private companyId: any;
  private employeeId: any;

  constructor(props: ITechSupportSetupProps) {
    super(props);
    this.state = {
      modalIsOpen: false,
      isChanged: false,
      loading: false,
      model: {},
      errorMessages: [],
    };
  }

  createModel(): ITechSupportRequest {
    const model: ITechSupportRequest = {
      reason: "",
      description: "",
    };
    return model;
  }

  async saveModel(): Promise<void> {
    this.techSupportForm.current?.validate();
    if (this.state.errorMessages.length > 0) {
      this.onValidateError(this.toastContainerId);
      return;
    }
    this.companyId = this.props.companyStore?.currentCompany?.id!;
    this.employeeId = this.props.companyStore?.currentCompany?.employeeId!;
    const modalModel = this.state.model;
    const selectedReason = this.techSupportForm.current?.getSelectedReason();
    modalModel.reason = selectedReason;
    //TODO
    this.setState({ ...this.state, loading: true });
    await this.props.techSupportStore
      ?.sendTechSupportRequest(
        this.companyId,
        this.employeeId,
        modalModel as ITechSupportRequest
      )
      .then(() => {
        this.onSaved();
        this.onSuccess(techSupportStrings.techSupportRequestSuccess);
      })
      .catch((e: any) => {
        toast.error(
          techSupportStrings.techSupportRequestError.replace(
            "{0}",
            techSupportStrings.contactPhoneNumber
          ),
          {
            autoClose: false,
            closeButton: true,
            position: "top-center",
            closeOnClick: false,
          }
        );
      })
      .finally(() => {
        this.setState({ ...this.state, loading: false });
        this.onClose();
      });
  }

  render() {
    return (
      <Stack>
        <Modal
          isOpen={this.state.modalIsOpen}
          onDismiss={this.onClose}
          isBlocking={true}
        >
          <ToastContainer
            containerId={this.toastContainerId}
            position="top-center"
          />
          <Stack>
            <StackItem>
              {this.onRenderHeader(techSupportStrings.techSupportRequestHeader)}
            </StackItem>
            <StackItem>
              <TechSupportForm
                model={this.state.model}
                ref={this.techSupportForm}
                onFieldChanged={this.onHandleModelFieldChange}
                dropdownFieldName={this.fieldDropdownId}
                errorMessages={this.state.errorMessages}
                onValidationResult={this.onHandleComponentValidationResult}
              />
            </StackItem>
            <StackItem>
              {this.onRenderFooter(
                async () => await this.saveModel(),
                techSupportStrings.submitButton,
                false
              )}
            </StackItem>
          </Stack>
          {this.state.loading && <ModalLoading />}
        </Modal>
      </Stack>
    );
  }
}
