import { RowMenuGrid } from "components/RowMenu";
import { Guid } from "guid-typescript";
import { iconNames, strings } from "../../../../utils";

export const ReportActionMenu: React.FC<any> = (props) => {
  const gridRowMenuItems = [
    {
      iconName: iconNames.gridDownload,
      action: props.onDownloadReport,
      tooltipContent: strings.downloadReport,
      row: props.data
    },
  ];
  return (
    <RowMenuGrid
      id={Guid.raw()}
      rowMenuItems={gridRowMenuItems}
      isSubmenu={true}
    />
  );
};
