import "./index.css";

import { createRoot } from "react-dom/client";

import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "components/auth/authConfig";

import { InteractionType, PublicClientApplication } from "@azure/msal-browser";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { loginRequest } from "components/auth/authConfig";
import AuthError from "components/auth/AuthError";
import { Provider } from "mobx-react";

import initializeStores from "./stores/storeInitializer";

import { BrowserRouter } from "react-router-dom";
import App from "./App";

const clientApp = new PublicClientApplication(msalConfig);
const stores = initializeStores();

const rootElement = document.getElementById("root") as HTMLElement;
const root = createRoot(rootElement);

const AppProvider = () => (
  <Provider {...stores}>
    <BrowserRouter>
      <MsalProvider instance={clientApp}>
        <MsalAuthenticationTemplate
          interactionType={InteractionType.Redirect}
          authenticationRequest={loginRequest}
          errorComponent={AuthError}
        >
          <App />
        </MsalAuthenticationTemplate>
      </MsalProvider>
    </BrowserRouter>
  </Provider>
);

root.render(<AppProvider />);
