import { DefaultButton, Stack, StackItem, Text } from "@fluentui/react";
import { inject } from "mobx-react";
import React from "react";

import Stores from "stores";
import ReportStore from "stores/reports/reportStore";
import UsersStore from "stores/users/usersStore";

import { IReportListItem, IUser } from "types";
import ReportList from "./components/ReportList";
import UserInfo from "./components/UserInfo";
import { getStyles } from "./home.styleNames";

import ModalLoading from "components/ModalLoading";
import { getCommonStyles } from "styles/common.styleNames";
import { themes } from "styles/themes/customTheme";
import { employeeStrings, iconNames, storeHelper, strings } from "utils";
import { getFormStyleNames } from "styles/form.styleNames";
import { autorun } from "mobx";
import CompanyStore from "stores/companies/companyStore";

export interface IHomeProps {
  reportStore?: ReportStore;
  usersStore?: UsersStore;
  companyStore?: CompanyStore;
}

export interface IHomeState {
  dowloadingReport: boolean;
  reports: IReportListItem[];
  user: IUser;
  selectedItems: IReportListItem[];
}

@inject(Stores.ReportStore, Stores.UsersStore, Stores.CompanyStore)
export class Home extends React.Component<IHomeProps, IHomeState> {
  constructor(props: IHomeProps) {
    super(props);
    this.state = {
      dowloadingReport: false,
      reports: [],
      user: {},
      selectedItems: [],
    };
  }
  private companyId: any;
  private employeeId: any;
  private autorunDisposer: any = null;
  private reportList = React.createRef<ReportList>();

  async componentDidMount() {
    this.autorunDisposer = autorun(async () => {
      if (
        this.props.companyStore?.currentCompany?.id !==
        this.props.companyStore?.originalCompany?.id
      )
        return;
      await this.reloadEmployeeInfo();
    });
  }

  componentWillUnmount() {
    if (this.autorunDisposer) this.autorunDisposer();
  }

  render() {   
    const { reportListWrapper } = getStyles();
    const { standardButtonStyles } = getFormStyleNames();
    const { pageWrapper, titleHeaderStyle, titleTextStyle } = getCommonStyles();
    return (
      <Stack styles={pageWrapper}>
        <StackItem styles={titleHeaderStyle}>
          <Text styles={titleTextStyle}>
            {employeeStrings.employeeFringeBenefitStatements}
          </Text>
        </StackItem>
        <StackItem>
          <UserInfo user={this.state.user} />
        </StackItem>

        <StackItem styles={reportListWrapper}>
          <Stack>
            <StackItem
              styles={{
                root: {
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  padding: "10px 0",
                },
              }}
            >
              <DefaultButton
                text={strings.download}
                onClick={this.handleDownloadSelectedReport}
                styles={standardButtonStyles}
                disabled={this.state.selectedItems.length === 0}
                iconProps={{
                  iconName:
                    this.state.selectedItems.length === 0
                      ? iconNames.multipleDowloadButtonDisabled
                      : iconNames.multipleDowloadButton,
                }}
              />
            </StackItem>
          </Stack>
          <ReportList
            onDownloadReport={this.handleDownloadReport}
            onOpenReport={this.handleOpenReport}
            onSelectionChanged={this.handleChangeSelected}
            ref={this.reportList}
          />
        </StackItem>
        {this.state.dowloadingReport && <ModalLoading theme={themes.dark} />}
      </Stack>
    );
  }

  private reloadEmployeeInfo = async () => {
    this.companyId = this.props.companyStore?.currentCompany.id;
    this.employeeId = this.props.companyStore?.currentCompany.employeeId;

    await this.reportList.current?.reloadGridData();
    let reports = this.props.reportStore?.reportList ?? [];
    var currentUser = this.props.usersStore?.currentUser ?? {};
    this.setState({
      reports: reports,
      user: currentUser,
    });
  };

  private handleChangeSelected = async (items: IReportListItem[]) => {
    this.setState({
      selectedItems: items,
    });
  };

  private handleDownloadReport = async (id: string, row?: any) => {
    this.setState({ dowloadingReport: true });
    await this.props.reportStore
      ?.downloadReport(this.companyId, this.employeeId, row?.id)
      .then((response) => {
        const url = window.URL.createObjectURL(
          new Blob([response.data], {
            type: "application/octet-stream",
          })
        );
        const link = document.createElement("a");
        link.href = url;

        link.setAttribute("download", `${row?.description}.pdf`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        this.setViewedStatus(row?.id, true);
        this.setState({ dowloadingReport: false });
      })
      .catch((e: any) => {
        this.setViewedStatus(row?.id, row?.isViewed);
        this.setState({ dowloadingReport: false });
        storeHelper.onSubmitError({ errors: ["File cannot be downloaded"] });
      });
  };

  private handleDownloadSelectedReport = async () => {
    this.setState({ dowloadingReport: true });
    const eid = this.state.user.employeeId!;
    await this.props.reportStore
      ?.employeeDownloadReports(
        this.companyId,
        this.employeeId,
        this.state.selectedItems?.map((i) => {
          return { employeeId: eid, reportId: i.id };
        }) ?? []
      )
      .then((response) => {
        const url = window.URL.createObjectURL(
          new Blob([response.data], {
            type: "application/octet-stream",
          })
        );
        const link = document.createElement("a");
        link.href = url;

        link.setAttribute("download", `reports.zip`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        this.setViewedAllStatus(true);
        this.setState({ dowloadingReport: false });
      })
      .catch((e: any) => {
        this.setState({ dowloadingReport: false });
        storeHelper.onSubmitError({ errors: ["Files cannot be downloaded"] });
      });
  };

  private handleOpenReport = async (report: IReportListItem) => {
    this.setState({ dowloadingReport: true });
    await this.props.reportStore
      ?.downloadReport(this.companyId, this.employeeId, report.id)
      .then((response) => {
        console.log(response);
        const url = window.URL.createObjectURL(
          new Blob([response.data], {
            type: "application/pdf",
          })
        );
        window.open(url, "_blank");
        this.setViewedStatus(report.id, true);
        this.setState({ dowloadingReport: false });
      })
      .catch((e: any) => {
        this.setViewedStatus(report.id, report.isViewed);
        this.setState({ dowloadingReport: false });
        storeHelper.onSubmitError({ errors: ["File cannot be opened"] });
      });
  };

  private setViewedStatus = (id: string, status: boolean) => {
    const updatedReport = this.state.reports.find((item) => item.id === id);

    if (updatedReport) {
      updatedReport.isViewed = status;
      this.setState({
        reports: this.state.reports.map((item) =>
          item.id === id ? updatedReport : item
        ),
      });
    }
  };

  private setViewedAllStatus = (status: boolean) => {
    this.setState({
      reports: this.state.reports.map((item) => {
        item.isViewed = status;
        return item;
      }),
    });
  };
}

export default Home;
