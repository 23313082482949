import {
  getId,
  Icon,
  IObjectWithKey,
  ITextStyles,
  ITheme,
  Selection,
  Text,
  TooltipHost,
} from "@fluentui/react";
import { CellStyle } from "ag-grid-community/dist/lib/entities/colDef";
import { NumberFormatValues, NumericFormat } from "react-number-format";
import { getMainPalette } from "../styles/mainPalette";
import { getSectionStyleNames } from "../styles/section.styleNames";
import { strings } from "../utils";
import { ErrorMessage } from "./ErrorMessage";

export default class ComponentHelper {
  public static renderRowMenuItem = (
    iconName: string,
    iconStyles: any,
    iconAction: any,
    itemKey: string,
    invisible?: boolean,
    iconClassName?: string,
    iconTooltipContent?: string
  ): JSX.Element => {
    const iconActionProps = {
      iconName: iconName,
      styles: iconStyles,
      className: iconClassName,
      onClick: iconAction,
      key: itemKey,
    };
    const tooltipId = getId(`tooltip-${itemKey}`);
    const { fieldTooltipStyles } = getSectionStyleNames();

    return invisible ? (
      <></>
    ) : iconTooltipContent ? (
      <TooltipHost
        content={iconTooltipContent}
        id={tooltipId}
        calloutProps={{ gapSpace: 0 }}
        styles={fieldTooltipStyles}
      >
        <Icon {...Object.assign({}, iconActionProps)} />
      </TooltipHost>
    ) : (
      <Icon {...Object.assign({}, iconActionProps)} />
    );
  };

  public static getElementErrorMessage = (
    errorMessages: Array<any>,
    errorKey: string,
    errorStyles?: any
  ): JSX.Element | undefined => {
    const errorMessage = errorMessages.find((error) => error.key === errorKey);
    return ComponentHelper.getWrappedElementErrorMessage(
      errorMessage ? errorMessage.error : undefined,
      errorStyles
    );
  };

  public static getWrappedElementErrorMessage = (
    error?: string,
    errorStyles?: any
  ): JSX.Element | undefined => {
    return error ? (
      <ErrorMessage text={error} containerStyles={errorStyles} />
    ) : undefined;
  };

  public static setGridSelection = (
    selection: Selection,
    items: Array<IObjectWithKey>,
    shouldClear: boolean
  ) => {
    selection.setItems(items, shouldClear);
  };

  public static onRenderNumericValue = (
    value?: number | string,
    prefixPresent?: boolean,
    theme?: ITheme,
    textStyles?: ITextStyles,
    prefixText?: string
  ): JSX.Element => {
    const numberContent = (
      <NumericFormat
        value={value}
        prefix={
          prefixPresent
            ? prefixText
              ? prefixText
              : strings.symbolDollar
            : undefined
        }
        thousandSeparator={prefixPresent}
        decimalSeparator={strings.dotSymbol}
        decimalScale={2}
        displayType={"text"}
        fixedDecimalScale={true}
      />
    );

    return (
      <>
        {theme && textStyles && (
          <Text theme={theme} styles={textStyles}>
            {numberContent}
          </Text>
        )}
        {!theme && !textStyles && <div>{numberContent}</div>}
      </>
    );
  };

  public static getDefaultCellStyle = (): CellStyle => {
    const defaultPalette = getMainPalette();
    return {
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: "rgba(169, 169, 169, 0.25)",
      borderRightWidth: "1px",
      borderRightStyle: "solid",
      borderRightColor: "rgba(169, 169, 169, 0.25)",
      color: defaultPalette.black,
      fontWeight: "normal",
    };
  };

  public static getErrorCellStyle = (): CellStyle => {
    const defaultPalette = getMainPalette();
    return {
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: defaultPalette.red,
      color: defaultPalette.red,
      fontWeight: "bold",
    };
  };

  public static numberMoneyIsAllowed = (
    values: NumberFormatValues
  ): boolean => {
    const val = Number(values.value);
    return val > -999999999999.99 && val < 999999999999.99;
  };

  public static numberDecimalIsAllowed = (
    values: NumberFormatValues
  ): boolean => {
    const val = Number(values.value);
    return val > -9999.99 && val < 9999.99;
  };

  public static numberZeroIsAllowed = (values: NumberFormatValues): boolean => {
    const val = Number(values.value);
    return val >= 0;
  };

  public static getInprogressIconCellStyle = (): CellStyle => {
    return {
      marginLeft: "-5px",
    };
  };
}
