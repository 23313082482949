import { IStackItemStyles, IStackStyles, ITextStyles } from "@fluentui/react";
import { getColorStyleNames } from "styles/color.styleNames";
import { getDefaultFonts } from "styles/fonts/fontDefault";

const { fontRegular } = getDefaultFonts();

export interface IReportListStyle {
  userInfoStyle: IStackStyles;
  userInfoWraper: IStackStyles;
  textStyle: ITextStyles;

  col1Style: IStackItemStyles;
  col2Style: IStackItemStyles;

  rowBoxStyle: IStackItemStyles;
  colBoxStyle: IStackItemStyles;

  col2TextStyle: ITextStyles;
  col2ValueTextStyle: ITextStyles;
}

const colors = getColorStyleNames();

export const getStyles = (): IReportListStyle => ({
  userInfoStyle: {
    root: {
      paddingTop: "10px",
      "@media(max-width: 800px)": {
        flexDirection: "column",
      },
    },
  },
  userInfoWraper: {
    root: {},
  },

  textStyle: {
    root: {
      fontFamily: fontRegular.fontFamily,
      color: colors.textColor,
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "14px",
      lineHeight: "19px",
      letterSpacing: "0.25px",
    },
  },
  col1Style: {
    root: {
      minWidth: "200px",
      paddingRight: "20px",
    },
  },
  col2Style: {
    root: {
      minWidth: "200px",
      paddingRight: "20px",
    },
  },
  rowBoxStyle: {
    root: {
      paddingBottom: "4px",
    },
  },
  colBoxStyle: {
    root: {
      minWidth: "135px",
    },
  },
  col2TextStyle: {
    root: {
      fontFamily: fontRegular.fontFamily,
      color: colors.textColor,
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "14px",
      lineHeight: "19px",
      letterSpacing: "0.25px",
    },
  },

  col2ValueTextStyle: {
    root: {
      fontFamily: fontRegular.fontFamily,
      color: colors.textColor,
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "14px",
      lineHeight: "19px",
      letterSpacing: "0.25px",
    },
  },
});
