import { IIconSubset } from "@fluentui/react";
import { iconNames } from "utils";

import { ReactComponent as AdminActionSlash } from "../images/admin-action-slash.svg";
import { ReactComponent as AdminAction } from "../images/admin-action.svg";
import { ReactComponent as AdminIcon } from "../images/admin-icon.svg";
import { ReactComponent as Employees } from "../images/employees.svg";
import { ReactComponent as ErrorMessage } from "../images/error-message.svg";
import { ReactComponent as Error401 } from "../images/Error401.svg";
import { ReactComponent as Error404 } from "../images/Error404.svg";
import { ReactComponent as Error500 } from "../images/Error500.svg";
import { ReactComponent as FirstPage } from "../images/first-page.svg";
import { ReactComponent as GridMoreDisabled } from "../images/grid-more-disabled.svg";
import { ReactComponent as GridMoreExpand } from "../images/grid-more-expand.svg";
import { ReactComponent as GridMore } from "../images/grid-more.svg";
import { ReactComponent as HamburgerMenuIcon } from "../images/hamburger-menu-icon.svg";
import { ReactComponent as LastPage } from "../images/last-page.svg";
import { ReactComponent as NavSideCheck } from "../images/nav-side-check.svg";
import { ReactComponent as NextPage } from "../images/next-page.svg";
import { ReactComponent as OptionGroups } from "../images/option-groups.svg";
import { ReactComponent as PrevPage } from "../images/prev-page.svg";
import { ReactComponent as QuickChart } from "../images/quick-chart.svg";
import { ReactComponent as Reports } from "../images/reports.svg";
import { ReactComponent as SearchClear } from "../images/search-clear.svg";
import { ReactComponent as SearchFilter } from "../images/search-filter.svg";
import { ReactComponent as ToggleCheck } from "../images/toggle-check.svg";
import { ReactComponent as ToggleUncheck } from "../images/toggle-uncheck.svg";
import { ReactComponent as MultipleDowloadButton } from "../images/multiple-download-button.svg";
import { ReactComponent as MultipleDowloadButtonDisabled } from "../images/multiple-download-button-disabled.svg";
import { ReactComponent as GridDownload } from "../images/grid-download.svg";

const commonIcons: IIconSubset = {
  icons: {
    [iconNames.error401]: <Error401 />,
    [iconNames.error404]: <Error404 />,
    [iconNames.error500]: <Error500 />,
    [iconNames.errorMessage]: <ErrorMessage />,
    [iconNames.hamburgerMenuIcon]: <HamburgerMenuIcon />,
    [iconNames.optionGroups]: <OptionGroups />,
    [iconNames.navSideCheck]: <NavSideCheck />,
    [iconNames.employees]: <Employees />,
    [iconNames.reports]: <Reports />,
    [iconNames.gridMore]: <GridMore />,
    [iconNames.gridMoreDisabled]: <GridMoreDisabled />,
    [iconNames.gridMoreExpand]: <GridMoreExpand />,
    [iconNames.toggleCheck]: <ToggleCheck />,
    [iconNames.toggleUncheck]: <ToggleUncheck />,
    [iconNames.searchFilter]: <SearchFilter />,
    [iconNames.searchClear]: <SearchClear />,
    [iconNames.firstPage]: <FirstPage />,
    [iconNames.lastPage]: <LastPage />,
    [iconNames.prevPage]: <PrevPage />,
    [iconNames.nextPage]: <NextPage />,
    [iconNames.quickChart]: <QuickChart />,
    [iconNames.adminIcon]: <AdminIcon />,
    [iconNames.adminAction]: <AdminAction />,
    [iconNames.adminActionSlash]: <AdminActionSlash />,
    [iconNames.multipleDowloadButton]: <MultipleDowloadButton />,
    [iconNames.multipleDowloadButtonDisabled]: <MultipleDowloadButtonDisabled />,
    [iconNames.gridDownload]: <GridDownload />,
  },
};

export default commonIcons;
