import Logout from "components/auth/Logout";
import { Route, Routes } from "react-router-dom";
import Main from "components/Main";
import Exception from "scenes/Home/Exception";

function AppRouteBuilder() {
  return (
    <Routes>
      <Route path="/exception/:errorCode" element={<Exception />} />
      <Route path="/logout" element={<Logout />} />
      <Route path="/*" element={<Main />} />
    </Routes>
  );
}

export default AppRouteBuilder;
