import {
  IButtonStyles,
} from "@fluentui/react";
import { getColorStyleNames } from "./color.styleNames";

export interface IFormStyleNames {
  primaryButtonStyles: IButtonStyles;
  standardButtonStyles: IButtonStyles;
  linkButtonStyles: IButtonStyles;
}

const colors = getColorStyleNames();

export const getFormStyleNames = (): IFormStyleNames => ({
  primaryButtonStyles: {
    root: {
      backgroundColor: colors.buttonColor,
      borderRadius: 3,
      fontWeight: 500,
      fontSize: 14,
      lineHeight: 16,
      minWidth: 134,
      textAlign: "center",
      letterSpacing: "0.1px",
      color: colors.buttonTextColor,
      height: 28,
      alignItems: "center",
    },
    rootDisabled: {
      backgroundColor: colors.disabledButtonColor,
      borderRadius: 3,
      fontWeight: 500,
      minWidth: 134,
      fontSize: 14,
      lineHeight: 16,
      textAlign: "center",
      letterSpacing: "0.1px",
      color: colors.buttonTextColor,
      height: 28,
      alignItems: "center",
    },
  },
  standardButtonStyles: {
    root: {
      backgroundColor: colors.buttonTextColor,
      borderRadius: 3,
      fontWeight: 500,
      fontSize: 14,
      lineHeight: 16,
      minWidth: 134,
      textAlign: "center",
      letterSpacing: "0.1px",
      height: 28,
      alignItems: "center",
    },
    rootDisabled: {
      backgroundColor: colors.disabledButtonColor,
      borderRadius: 3,
      fontWeight: 500,
      minWidth: 134,
      fontSize: 14,
      lineHeight: 16,
      textAlign: "center",
      letterSpacing: "0.1px",
      color: colors.buttonTextColor,
      height: 28,
      alignItems: "center",
    },
  },
  linkButtonStyles: {
    root: {
      background: colors.formBackgroundColor,
      borderRadius: 3,
      fontWeight: 500,
      fontSize: 14,
      lineHeight: 16,
      textAlign: "center",
      letterSpacing: "0.1px",
      color: colors.linkColor,
    },
  },
});
