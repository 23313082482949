import { ICheckboxStyles, IChoiceGroupStyles, IComboBoxStyles, IDropdownStyles, IIconStyles, IStackItemStyles, IStackStyles, IStyle, ITextFieldStyleProps, ITextFieldStyles, ITextStyles, ITooltipHostStyles } from "@fluentui/react";
import { getColorListStyleNames, getColorStyleNames } from "./color.styleNames";
import { fontFamilyRoboto } from "./fonts/fontDefault";

export interface IGenericStyleNames {
  labelStyle: IStyle;
  placeHolderStyle: IStyle;
}

export interface ISectionStyleNames {
  bodyStyles: IStackStyles;
  bodyErrorStyles: IStackStyles;
  bodyItemRateablyStyles: IStackStyles;
  bodyItemShortStyles: IStackStyles;
  bodyItemLongStyles: IStackStyles;
  bodyItemQuarterStyles: IStackStyles;
  fieldStyles: IStackItemStyles;
  fieldExpandedStyles: IStackItemStyles;
  fieldTooltipStyles: ITooltipHostStyles;
  fieldInputStyles: Partial<ITextFieldStyles>;
  iconTooltipStyles: IIconStyles;
  checkboxStyles: ICheckboxStyles;
  choiceGroupHorizontalStyles: IChoiceGroupStyles;
  headerWrapper: IStackStyles;
  //defaultTextStyles: ITextStyles;
  fieldInputShortStyles: Partial<ITextFieldStyles>;
  header: Partial<ITextStyles>;
  internalHeader: Partial<ITextStyles>;
  headerNote: ITextStyles;
  dropdownStyles: Partial<IDropdownStyles>;
  headerSection: IStackStyles;
  headerTooltipIcon: Partial<IIconStyles>;
  comboboxStyles: Partial<IComboBoxStyles>;
  iconCursor: IIconStyles;
}

const colors = getColorStyleNames();
const colorsList = getColorListStyleNames();
export const getGenericStyleNames = (): IGenericStyleNames => ({
  labelStyle: {
    color: colors.textColor,
    fontWeight: "400",
    letterSpacing: "0.25px",
    fontFamily: fontFamilyRoboto,
    fontStyle: "normal",
    fontSize: 14,
    lineHeight: 19,
  },
  placeHolderStyle: {
    color: colors.placeholderColor,
    fontWeight: "400",
    letterSpacing: "0.25px",
    fontFamily: fontFamilyRoboto,
    fontStyle: "normal",
    fontSize: 14,
    lineHeight: 19,
  }
});

export const getSectionStyleNames = (): ISectionStyleNames => ({
  bodyStyles: {
    root: {
      background: "#FDFDFD",
      border: "1px solid #A7A7A7",
      borderRadius: "3px",
      marginLeft: 22,
      marginRight: 22,
      padding: "22px 21px 35px 22px",
      maxHeight: "calc(100vh - 205px)",
      overflowX: "hidden",
    },
  },
  bodyErrorStyles: {
    root: {
      background: "#FDFDFD",
      border: "2px solid #EF273C",
      borderRadius: "3px",
      marginLeft: 22,
      marginRight: 22,
      padding: 16,
    },
  },
  bodyItemRateablyStyles: { root: { width: "33%" } },
  bodyItemShortStyles: { root: { width: "50%" } },
  bodyItemLongStyles: { root: { width: "100%" } },
  bodyItemQuarterStyles: { root: { width: "25%" } },
  fieldStyles: {
    root: {
      marginLeft: 16,
      marginRight: 16,
      display: "flex",
    },
  },
  fieldExpandedStyles: {
    root: {
      marginLeft: 16,
      marginRight: 16,
    },
  },
  fieldTooltipStyles: { root: { display: "inline-block" } },
  fieldInputStyles: {
    root: { width: "100%" },
    wrapper: {
      selectors: {
        label: getGenericStyleNames().labelStyle,
      },
    },
  },
  iconTooltipStyles: {
    root: {
      paddingLeft: 10,
      paddingTop: 3,
      fontSize: 18,
      cursor: "pointer",
    },
  },
  checkboxStyles: {
    root: {
      marginTop: 4,
    },
    checkbox: {
      height: 12,
      width: 12,
      marginTop: 3,
    },
    text: {
      fontSize: 12,
      lineHeight: 16,
      display: "flex",
      alignItems: "center",
      color: "#636363",
      marginTop: 2,
    },
  },
  choiceGroupHorizontalStyles: {
    flexContainer: { display: "flex" },
    label: { margin: 20 },
  },
  headerWrapper: {
    root: {
      marginLeft: 22,
      marginRight: 22,
      marginTop: 20,
    },
  },
  //defaultTextStyles: textStyle as ITextStyles,
  fieldInputShortStyles: {
    wrapper: {
      selectors: {
        label: getGenericStyleNames().labelStyle,
      },
    },
  },
  header: {
    root: {
      color: "#636363",
      fontWeight: 500,
      paddingBottom: 7,
      letterSpacing: "0.1px",
      fontStyle: "normal",
      fontSize: 14,
      lineHeight: 16,
    },
  },
  internalHeader: {
    root: {
      color: "#636363",
      paddingBottom: 5,
      letterSpacing: "0.25px",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: 14,
      lineHeight: 19,
    },
  },
  headerNote: {
    root: {
      paddingLeft: 5,
      paddingTop: 2,
      color: "#636363",
      fontSize: 12,
      fontWeight: 400,
      letterSpacing: "0.1px",
      fontStyle: "normal",
      lineHeight: 14,
    },
  },
  dropdownStyles: {
    label: getGenericStyleNames().labelStyle,
    errorMessage: {
      color: "rgb(164, 38, 44)",
      fontSize: 12,
      fontWeight: "normal",
    },
  },
  headerSection: {
    root: {
      marginLeft: 0,
    },
  },
  headerTooltipIcon: {
    root: {
      padding: "1px 0px 0px 10px",
      fontSize: 0,
    },
  },
  comboboxStyles: {
    label: getGenericStyleNames().labelStyle,
  },
  iconCursor: {
    root: {
      cursor: "pointer",
    },
  },
});

export function getTextFieldStyles(
  props: ITextFieldStyleProps
): Partial<ITextFieldStyles> {
  const { hasErrorMessage } = props;
  return {
    root: {
      width: "100%",
    },
    wrapper: {
      selectors: {
        label: getGenericStyleNames().labelStyle,
      },
    },
    fieldGroup: [
      hasErrorMessage ? {
        borderColor: props.theme.palette.red,
        borderWidth: 2,
      } : {
         border: "1px solid",
         boxSizing: "border-box",
         borderRadius: "3px",
         borderColor: colorsList.borderColor,
      },
    ],
    errorMessage: {
      padding: 0,
    },
  };
}
