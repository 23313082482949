import {
  IStackStyles,
  IIconStyles,
  IStackItemStyles,
  ILabelStyles,
  ITextStyles,
  DefaultPalette,
} from "@fluentui/react";
import { getColorStyleNames } from "styles/color.styleNames";

export interface ISiderMenuStyleNames {
  hamburgerMenuIconWrapper: IStackStyles;
  hamburgerMenuIcon: IIconStyles;
  labelWrapper: IStackItemStyles;
  label: Partial<ILabelStyles>;
  labelSeparator: IStackStyles;
  versionText: Partial<ITextStyles>;
  versionWrapper: IStackStyles;
  versionTextWrapper: IStackItemStyles;
}

const colors = getColorStyleNames();

export const getStyleNames = (): ISiderMenuStyleNames => ({
  hamburgerMenuIcon: {
    root: {
      cursor: "pointer",
      paddingLeft: 6,
    },
  },
  hamburgerMenuIconWrapper: {
    root: { height: 41 },
  },
  label: {
    root: {
      color: colors.lightTextColor,
      fontWeight: 600,
      fontSize: 14,
      lineHeight: 20,
      letterSpacing: "0.25px",
    },
  },
  labelWrapper: {
    root: {
      display: "inline-block",
      alignSelf: "center",
    },
  },
  labelSeparator: {
    root: { opacity: 0.5, borderBottom: "1px solid #FDFDFD" },
  },
  versionText: {
    root: {
      color: "#FFFFFF",
      fontWeight: 600,
    },
  },
  versionWrapper: {
    root: {
      padding: "10px 0px",
      position: "absolute",
      bottom: 0,
      alignSelf: "center",
    },
  },
  versionTextWrapper: {
    root: {
      color: DefaultPalette.white,
    },
  },
});
