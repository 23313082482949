import { IComboBox, IComboBoxOption, Stack, StackItem } from "@fluentui/react";
import { getStyles } from "./header.styleNames";
import { buildingIcon } from "images";
import UserProfileMenu from "components/UserProfileMenu";
import { inject } from "mobx-react";
import Stores from "stores";
import UsersStore from "stores/users/usersStore";
import Ellipsis from "react-ellipsis-component";
import CompanyStore from "stores/companies/companyStore";
import { OnsiComboBox } from "../ComboBox";
import { getStyleNames } from "../ComboBox/combobox.styleNames";
import { createRef, useEffect, useState } from "react";
import { generatePath, useLocation, useNavigate } from "react-router-dom";
import RouterStore from "stores/routing/routerStore";

type IHeaderProps = {
  usersStore?: UsersStore;
  companyStore?: CompanyStore;
  routerStore?: RouterStore;
};

const Header = inject(
  Stores.UsersStore,
  Stores.CompanyStore,
  Stores.RouterStore
)((props: IHeaderProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    wrapperStyle,
    containerStyle,
    headerGroupStyle,
    userNameStyle,
    companyWrapperStyle,
    companyStyle,
  } = getStyles();
  const { formComboBox, formCaretDownButtonStyles } = getStyleNames();
  const cbCompanies = createRef<IComboBox>();
  const companyListOptions: Array<IComboBoxOption> = [];
  const [selectedCompany, setSelectedCompany] = useState(
    props.companyStore?.currentCompany?.id
  );

  useEffect(() => {
    setSelectedCompany(props.companyStore?.currentCompany?.id);
  }, [props.companyStore?.currentCompany?.id]);

  const onCompanyChange = (ev: any, option?: IComboBoxOption): void => {
    if (option) {
      const id = option.key.toString();
      setSelectedCompany(id);
      props.companyStore?.setOriginalCompany(id);
      redirectToNewCompany(id);
    }
  };

  const redirectToNewCompany = (id: string) => {
    const router = props.routerStore?.getRouter(location.pathname);
    var path = generatePath(router.path, {
      id: id,
    });
    navigate(path);
  };

  props.companyStore?.companiesList.forEach((option: any) => {
    companyListOptions.push({
      key: option.id!,
      text: option.displayName!,
    });
  });

  return (
    <Stack styles={wrapperStyle}>
      <Stack styles={containerStyle}>
        <Stack styles={headerGroupStyle}>
          <Stack styles={companyWrapperStyle} horizontal>
            <StackItem align="center" styles={companyStyle.logoStyle}>
              <img src={buildingIcon} alt="company logo" />
            </StackItem>
            <StackItem align="center" styles={companyStyle.titleStyle}>
              {props.companyStore?.companiesList?.length! <= 1 && (
                <Ellipsis
                  text={props.companyStore?.currentCompany?.displayName!}
                  maxLine={1}
                  ellipsis={true}
                />
              )}
              {props.companyStore?.companiesList?.length! > 1 && (
                <StackItem>
                  <OnsiComboBox
                    options={companyListOptions}
                    selectedKey={selectedCompany}
                    onChange={onCompanyChange}
                    iconButtonProps={{
                      iconProps: {
                        iconName: "CaretSolidDown",
                      },
                    }}
                    styles={formComboBox}
                    caretDownButtonStyles={formCaretDownButtonStyles}
                    componentRef={cbCompanies}
                  />
                </StackItem>
              )}
            </StackItem>
          </Stack>
        </Stack>
        <StackItem align="center" styles={userNameStyle}>
          <Ellipsis
            text={props.usersStore?.currentUser?.displayName!}
            maxLine={1}
            ellipsis={true}
          />
        </StackItem>
        <Stack styles={headerGroupStyle} horizontal>
          <StackItem align="center">
            <UserProfileMenu />
          </StackItem>
        </Stack>
      </Stack>
    </Stack>
  );
});
export default Header;
