import { IStackItemStyles, IStackStyles } from "@fluentui/react";
import { getColorStyleNames } from "styles/color.styleNames";

export interface ILayoutStyle {
  siderMenuWrapper: IStackStyles;
  layoutStyle: IStackStyles;
  headerStyle: IStackItemStyles;
  contentStackStyle: IStackItemStyles;
  contentStyle: IStackItemStyles;
  footerStyle: IStackItemStyles;
}

const colors = getColorStyleNames();

export const getStyles = (): ILayoutStyle => ({
  siderMenuWrapper: {
    root: {
      backgroundColor: colors.navMenuBackgroundColor,
      maxWidth: 210,
      height: "100vh",
      maxHeight: "100vh"
    }
  },
  layoutStyle: {
    root: {
      background: colors.contentBackgroundColor,
    },
  },
  headerStyle: {
    root: {
      background: colors.headerBackgroundColor,
    },
  },
  contentStyle: {
    root: {
      background: colors.contentBackgroundColor,
      minHeight: "300px;",
    },
  },
  contentStackStyle: {
    root: {
      width: "100%",
    },
  },
  footerStyle: {
    root: {
      background: colors.footerBackgroundColor,
    },
  },
});
