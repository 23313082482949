const strings = {
  requiredSymbol: "*",
  dateFormatDatePicker: "DDMMMYYYY",
  pleaseInputAllRequiredFields: "Please input all required fields",
  redirectUrlLocalKey: "redirectUrlLocalKey",
  appLabel: "OnsiSoft",
  home: "Home",
  defaultNewAction: "new",
  dash: "-",
  yes: "Yes",
  no: "No",
  symbolDollar: "$",
  dotSymbol: ".",
  notAssigned: "N/A",
  results: "Result(s)",
  show: "Show",
  all: "All",
  email: "Email",
  dateTimeGridCellFormat: "DDMMMYYYY",
  loading: "Loading",
  selectAll: "Select All",
  download: "Download",
  downloadReport: "Download Report",
};

export default strings;
