import { NavigateOptions } from "react-router-dom";

export default class RouterHelper {
  public static getNavigateOptions = (
    pathname: string | undefined
  ): NavigateOptions | undefined => {
    return pathname
      ? ({ state: { from: pathname } } as NavigateOptions)
      : undefined;
  };
  
}
