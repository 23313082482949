import { ITextStyles } from "@fluentui/react";
import { getMainPalette } from "../../../styles/mainPalette";

export interface ICellTooltipStyleNames {
  text: Partial<ITextStyles>;
}

const defaultPalette = getMainPalette();
export const getCellTooltipStyleNames = (): ICellTooltipStyleNames => ({
  text: {
    root: {
      color: defaultPalette.white,
    },
  },
});
