import { useEffect } from "react";
import { useMsal } from "@azure/msal-react";

function Logout() {
  const { instance } = useMsal();

  useEffect(() => {
    instance.logoutRedirect({
      postLogoutRedirectUri: process.env.REACT_APP_APP_BASE_URL,
    });
  }, [instance]);

  return <></>;
}
export default Logout;
