import {
  INavStyles,
  IIconStyles,
  IStackStyles,
  IButtonStyles,
} from "@fluentui/react";
import { getColorStyleNames } from "styles/color.styleNames";
import { fontFamilyRoboto } from "styles/fonts/fontDefault";


export interface IWideNavMenuStyleNames {
  navMenu: Partial<INavStyles>;
  navMenuItemIcon: Partial<IIconStyles>;
  navMenuWrapper: IStackStyles;
  navMenuScrollWrapper: IStackStyles;
  textName: Partial<IButtonStyles>;
  textSmallName: Partial<IButtonStyles>;
  iconLink: Partial<IIconStyles>;
}

const colors = getColorStyleNames();

export const getStyleNames = (
  isMenuItemFocusable?: boolean
): IWideNavMenuStyleNames => ({
  navMenu: {
    compositeLink: {
      backgroundColor: colors.navMenuBackgroundColor,
    },
    chevronButton: {
      color: colors.buttonTextColor,
      selectors: {
        ".ms-Nav-compositeLink:hover &": {
          color: colors.buttonTextColor,
        },
        "&::after": {
          border: "none",
        },
      },
      width: 24,
      marginLeft: 3,
    },
    link: {
      selectors: {
        ".ms-Nav-compositeLink:hover &": {
          color: colors.buttonTextColor,
          backgroundColor: "#0A53AE",
          textDecoration: "none",
        },
        i: {
          color: "white !important",
          alignSelf: "baseline",
          margin: 0,
          paddingTop: 4,
          marginLeft: 13,
        },
        "&::after": {
          borderLeft: isMenuItemFocusable
            ? undefined
            : `2px solid ${colors.buttonTextColor}`,
        },
      },
    },
    navItem: {
      selectors: {
        ".is-selected a": {
          backgroundColor: "#0A53AE"
        },
        ".is-selected button": {
          backgroundColor: "#0A53AE",
        },
        ".is-expanded i": {
          color: "white !important",
          alignSelf: "baseline",
          margin: 0,
          paddingTop: 4,
          paddingLeft: 13,
        },
        "i svg path": {
          fill: colors.buttonTextColor,
        },
      },
    },
    linkText: {
      fontFamily: fontFamilyRoboto,
      fontSize: 16,
      fontWeight: 500,
      lineHeight: 28,
      display: "flex",
      letterSpacing: "0.15px",
      color: colors.buttonTextColor,
    },
  },
  navMenuItemIcon: {
    root: {
      marginLeft: 10,
    },
  },
  navMenuWrapper: {
    root: {
      backgroundColor: colors.navMenuBackgroundColor,
      position: "relative",
    },
  },
  navMenuScrollWrapper: {
    root: {
      maxHeight: "calc(100vh - 80px)",
      overflowX: "hidden",
      width: 210,
    },
  },
  textName: {
    root: {
      fontSize: 16,
      lineHeight: 24,
      display: "flex",
      letterSpacing: "0.44px",
      color: colors.buttonTextColor,
      alignItems: "center",
    },
    rootHovered: {
      fontSize: 16,
      lineHeight: 24,
      display: "flex",
      letterSpacing: "0.44px",
      color: colors.buttonTextColor,
      alignItems: "center",
    },
  },
  textSmallName: {
    root: {
      fontSize: 14,
      lineHeight: 19,
      display: "flex",
      letterSpacing: "0.25px",
      color: colors.buttonTextColor,
      alignItems: "center",
      marginLeft: isMenuItemFocusable ? -30 : 0,
    },
    rootHovered: {
      fontSize: 14,
      lineHeight: 19,
      display: "flex",
      letterSpacing: "0.25px",
      color: colors.buttonTextColor,
      alignItems: "center",
      marginLeft: isMenuItemFocusable ? -30 : 0,
    },
  },
  iconLink: {
    root: {
      height: "none",
      margin: 0,
      paddingTop: "8px !important",
      paddingRight: "5px !important",
      selectors: {
        "svg path": {
          fill: colors.buttonTextColor,
        },
        svg: {
          paddingTop: 3,
        },
      },
    },
  },
});
