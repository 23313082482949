import React from "react";
import { inject } from "mobx-react";
import {
  Nav,
  INavLink,
  INavLinkGroup,
  Stack,
  IIconProps,
  Text,
  ITheme,
  ActionButton,
} from "@fluentui/react";
import { getStyleNames } from "./wideNavMenu.styleNames";
import Stores from "stores";
import Loading from "components/Loading";
import { IHistoryRouterProps } from "types";
import { iconNames, authUtils } from "utils";
import routerHelper from "components/routerHelper";
import CompanyStore from "stores/companies/companyStore";
import formHelper from "utils/formHelper";
import { generatePath } from "react-router-dom";

export interface IWideNavMenuProps extends IHistoryRouterProps {
  theme?: ITheme;
  companyStore?: CompanyStore;
}

export interface IWideNavMenuState {
  loading: boolean;
  rootGroupMenuLinks: Array<INavLinkGroup>;
  selectedMenuItem?: INavLink;
  selectedKey?: string;
}

@inject(Stores.RouterStore, Stores.CompanyStore)
export default class WideNavMenu extends React.Component<
  IWideNavMenuProps,
  IWideNavMenuState
> {
  constructor(props: IHistoryRouterProps) {
    super(props);
    this.state = {
      loading: true,
      rootGroupMenuLinks: [],
      selectedMenuItem: undefined,
    };
  }

  async componentDidMount() {
    await this.updateWideNavMenuRouter(this.props.location).then(() => {
      this.loadWideNavMenu();
      this.setState({
        loading: false,
      });
    });
  }

  loadWideNavMenu() {
    const rootNavMenuLinks: INavLink[] = [];
    this.props.routerStore?.NavRouters.forEach((navRouter: any) => {
      if (
        !navRouter.router.permission ||
        (navRouter.router.permission &&
          authUtils.isInRole(
            navRouter.router.permission,
            this.props.companyStore?.currentCompany?.roles!
          ))
      ) {
        const link = this.getNavMenuItem(navRouter);
        if (link) rootNavMenuLinks.push(link);
      }
    });
    this.setState({
      rootGroupMenuLinks: [
        {
          links: rootNavMenuLinks,
        },
      ],
    });
  }

  updateWideNavMenu = () => {
    this.setState(
      {
        selectedMenuItem: undefined,
        selectedKey: "",
      },
      () => this.loadWideNavMenu()
    );
  };

  getNavMenuItem = (navRouter: any): any => {
    if (navRouter.router.excludeInMenu === true) return undefined;
    const links: INavLink[] = [];
    const { navMenuItemIcon } = getStyleNames();
    if (navRouter.items && navRouter.items.length > 0) {
      navRouter.items.forEach((navRouterItem: any) => {
        const link = this.getNavMenuItem(navRouterItem);
        if (link) links.push(link);
      });
    }

    let iconProps: IIconProps | undefined =
      navRouter.router.icon && navRouter.router.isExpanded
        ? {
            styles: navMenuItemIcon,
            iconName: navRouter.router.icon,
          }
        : {
            iconName: navRouter.router.icon,
          };
    if (!navRouter.router.icon) iconProps = undefined;

    const menuItem = {
      name: navRouter.router.title,
      url: navRouter.router.path,
      key: navRouter.router.key,
      parent: navRouter.router.parent ? navRouter.router.parent : undefined,
      isGroup: navRouter.router.isExpanded,
      isExpanded:
        navRouter.router.isExpanded && navRouter.router.parent?.length === 1,
      isFocusable: navRouter.router.isFocusable,
      focusableSelector: navRouter.router.focusableSelector,
      links: links,
      iconProps: iconProps,
      isNormalMenuItem: navRouter.router.isNormalMenuItem,
      isSelectedFocusable: undefined,
      isSelectedPage: false,
      style: {},
    };
    if (this.state.selectedMenuItem) {
      menuItem["isSelectedFocusable"] =
        this.state.selectedMenuItem.key === menuItem.key &&
        menuItem.isFocusable;
      menuItem["isSelectedPage"] =
        this.state.selectedMenuItem.parent === menuItem.key;
    }
    if (menuItem["isSelectedPage"]) {
      menuItem["style"] = { borderLeft: "2px solid #ffffff", marginLeft: 2 };
      menuItem["isExpanded"] =
        menuItem["isSelectedPage"] && navRouter.router.isExpanded;
    }
    menuItem["isExpanded"] =
      this.props.routerStore?.currentRouter.path === menuItem.url;
    return menuItem;
  };

  onRenderLink = (props?: INavLink): JSX.Element => {
    let stateClass = "";
    switch (props!.state) {
      case "complete":
        stateClass = "state-complete";
        break;
      case "inprogress":
        stateClass = "state-in-progress";
        break;
      default:
        stateClass = "state-none";
        break;
    }
    let imgClassName = props!.imgClassName ? props!.imgClassName : "img-none";
    const { iconLink, textName, textSmallName } = getStyleNames(
      props?.isSelectedFocusable
    );

    const customRenderer = (
      <Stack>
        <Text className={imgClassName}></Text>
        <Text className={stateClass}></Text>
        <ActionButton
          iconProps={{
            iconName: props?.isSelectedFocusable
              ? iconNames.navSideCheck
              : undefined,
            styles: iconLink,
          }}
          onClick={() => {}}
          styles={props?.isNormalMenuItem ? textName : textSmallName}
        >
          {props!.name}
        </ActionButton>
      </Stack>
    );
    return customRenderer;
  };

  onLinkClick = (ev?: any, item?: INavLink) => {
    item!.isExpanded = !item!.isExpanded;
    ev!.preventDefault();
    const router = this.props.routerStore?.getRouter(item?.url!);
    const currentRouter = this.props.routerStore?.currentRouter;
    this.setState(
      {
        selectedMenuItem: item,
        selectedKey: item?.key,
      },
      () => this.loadWideNavMenu()
    );

    let locationState = undefined;
    if (item?.isFocusable)
      this.props.routerStore?.setCurrentFocusableMenu(item?.focusableSelector);
    if (router.path !== currentRouter?.path) {
      if (item?.isFocusable) {
        locationState = {
          detail: item.focusableSelector,
        };
        this.props.routerStore?.setCurrentFocusableMenu(item.focusableSelector);
      }
      const navigateOptions = locationState
        ? {
            state: locationState,
          }
        : routerHelper.getNavigateOptions(window.location.pathname);

      var companyId = undefined;
      const matchParams = this.props.routerStore?.getMatchParams(
        this.props.location.pathname
      );
      if (matchParams && formHelper.isResourceExist(matchParams.id))
        companyId = matchParams.id;
      var path = companyId
        ? generatePath(item?.url!, { id: companyId })
        : undefined;

      this.props.navigate(path, navigateOptions);
    }
  };

  render() {
    if (this.state.loading) return <Loading />;
    const { navMenu, navMenuWrapper, navMenuScrollWrapper } = getStyleNames(
      this.state.selectedMenuItem?.isFocusable
    );
    return (
      <Stack styles={navMenuWrapper}>
        <Stack styles={navMenuScrollWrapper}>
          <Nav
            onRenderLink={this.onRenderLink}
            onLinkClick={this.onLinkClick}
            styles={navMenu}
            groups={this.state.rootGroupMenuLinks}
            theme={this.props.theme}
            selectedKey={this.state.selectedKey}
          />
        </Stack>
      </Stack>
    );
  }

  private async updateWideNavMenuRouter(location: any) {
    const router = this.props.routerStore?.getRouter(location.pathname);
    this.props.routerStore?.updateRouters(router);
  }
}
