import { IStackItemStyles, IStackStyles } from "@fluentui/react";
import { getColorStyleNames } from "styles/color.styleNames";
import { getDefaultFonts } from "styles/fonts/fontDefault";
const { fontRegular } = getDefaultFonts();

export interface ICompanyStyle {
  logoStyle: IStackItemStyles;
  titleStyle: IStackItemStyles;
}

export interface IHeaderStyle {
  wrapperStyle: IStackStyles;
  containerStyle: IStackStyles;
  headerGroupStyle: IStackStyles;
  userNameStyle: IStackItemStyles;
  companyWrapperStyle: IStackStyles;
  companyStyle: ICompanyStyle;
  settingButtonStyle: IStackItemStyles;
  helpButtonStyle: IStackItemStyles;
}

const colors = getColorStyleNames();
export const getStyles = (): IHeaderStyle => ({
  wrapperStyle: {
    root: {
      margin: "0 auto",
      width: "100%",
      padding: "0 15px",
      color: colors.textColor,
    },
  },
  containerStyle: {
    root: {
      display: "flex",
      width: "100%",
      flexDirection: "row",
      justifyContent: "space-between",
      padding: "6px 0",
      alignItems: "center",
    },
  },
  headerGroupStyle: {
    root: {
      // flexBasis: "calc(100% / 3)",
      // justifyContent: "flex-end",
    },
  },
  userNameStyle: {
    root: {
      fontFamily: fontRegular.fontFamily,
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "16px",
      lineHeight: "28px",
      textAlign: "center",
      flexBasis: "calc(100% / 3)",
    },
  },
  companyWrapperStyle: {
    root: {
      alignSelf: "center",
    },
  },
  companyStyle: {
    logoStyle: {
      root: {
        width: "24px",
        height: "24px",
        justifyContent: "center",
      },
    },
    titleStyle: {
      root: {
        paddingLeft: "9px",
        fontFamily: fontRegular.fontFamily,
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "24px",
        lineHeight: "28px",
      },
    },
  },
  settingButtonStyle: {
    root: {},
  },
  helpButtonStyle: {
    root: {
      padding: "0 20px",
    },
  },
});
