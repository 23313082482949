import { strings } from ".";
import moment from "moment";

export default class DateHelper {
  public static toFormattedDateString = (
    dateValue?: Date
  ): string | undefined => {
    return dateValue
      ? moment(dateValue).format(strings.dateFormatDatePicker).toUpperCase()
      : undefined;
  };

  public static getFormattedForDatePicker = (
    date?: Date,
    dateFormat: string = strings.dateFormatDatePicker
  ): string => {
    return moment(date).format(dateFormat).toUpperCase();
  };
}
