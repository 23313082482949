import React from "react";
import { Stack, StackItem, Text } from "@fluentui/react";
import { getStyleNames } from "./header.styleNames";

export interface IOptionsConfiguratorHeaderProps {
  title?: string;
  onClose?: () => void;
  customTitleContent?: JSX.Element;
}
export const OptionsConfiguratorHeader: React.FC<IOptionsConfiguratorHeaderProps> =
  (props) => {
    const { headerStyles, headerTextStyles } = getStyleNames();

    return (
      <Stack horizontal>
        <StackItem styles={headerStyles} align="auto">
          {!props.customTitleContent && (
            <Text styles={headerTextStyles}>{props.title}</Text>
          )}
          {props.customTitleContent && <>{props.customTitleContent}</>}
        </StackItem>
      </Stack>
    );
  };
