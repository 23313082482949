import { action, makeObservable } from "mobx";
import {
  createTheme,
  Customizations,
  ITheme,
  loadTheme,
  registerIcons,
} from "@fluentui/react";
import commonIcons from "../../styles/commonIcons";
import themeDefault from "styles/themes/themeDefault";

export default class AppearanceStore {
  theme: ITheme = {} as ITheme;
  constructor() {
    makeObservable(this);
  }

  get CurrentTheme(): ITheme {
    return this.theme;
  }
  
  @action
  initialize(): void {
    registerIcons(commonIcons);
    const theme = createTheme(themeDefault);

    if (theme) {
      Customizations.applySettings(theme);
      this.theme = theme;
      loadTheme(this.theme);
    }
  }
}
