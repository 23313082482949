import { RedirectRequest, Configuration, LogLevel } from "@azure/msal-browser";
import { b2cPolicies } from "./policies";

export const b2cApplicationScopes: string =
  process.env.REACT_APP_AZURE_B2C_APP_SCOPES ?? "";

export const isIE = () => {
  const ua = window.navigator.userAgent;
  const msie = ua.indexOf("MSIE ") > -1;
  const msie11 = ua.indexOf("Trident/") > -1;

  // If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
  // const isEdge = ua.indexOf("Edge/") > -1;

  return msie || msie11;
};

export const msalConfig: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_AZURE_B2C_CLIENT_ID!,
    authority: b2cPolicies.authorities.signUpSignIn,
    knownAuthorities: [b2cPolicies.authorityDomain],
    redirectUri: process.env.REACT_APP_APP_BASE_URL,
    postLogoutRedirectUri: process.env.REACT_APP_APP_BASE_URL,
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: isIE(),
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
        }
      },
    },
  },
};

export const loginRequest: RedirectRequest = {
  scopes: ["openid", ...[b2cApplicationScopes]],
};
