import { IStackStyles, ISpinnerStyles } from "@fluentui/react";

export interface ILoadingStyleNames {
  loadingWrapper: IStackStyles;
  spinner: ISpinnerStyles;
}

export const getStyleNames = (): ILoadingStyleNames => ({
  loadingWrapper: { root: { paddingTop: 100, textAlign: "center" } },
  spinner: {
    circle: {
      height: 48,
      width: 48,
      borderWidth: 3
    }
  }
});
