const techSupportStrings = {
  reasonLabel: "Reason",
  selectOne: "Select One",
  descriptionLabel: "Description (min 20 characters)",
  techSupportRequestSuccess: "Tech Support Request was successfully created",
  techSupportRequestError:
    "Looks like we need to obtain additional verification for the email you are trying to use. In order to do so, please call {0} and select ‘Client Services’ when prompted.  We look forward to assisting you!",
  contactPhoneNumber: "(210) 547-3672",
  techSupportRequestHeader: "Employee Portal Tech Support Request",
  submitButton: "Submit",
  techSupportRequestLink: "Click for Tech Support",
};
export default techSupportStrings;
