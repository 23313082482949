import { strings } from "utils";

export default class FormHelper {
  public static getLabelText = (
    fieldLabel: string,
    fieldIsRequired: boolean = true
  ): string => {
    return fieldIsRequired
      ? `${fieldLabel} ${strings.requiredSymbol}`
      : fieldLabel;
  };
  
  public static getErrorMessage = (
    errorMessages: Array<any>,
    errorKey: string
  ): string | undefined => {
    const errorMessage = errorMessages.find((error) => error.key === errorKey);
    return errorMessage ? errorMessage.error : undefined;
  };

  public static removeErrorMessage = (
    errorMessages: Array<any>,
    errorKey: string
  ) => {
    if (errorMessages.length === 0) return;

    const errorIndex = errorMessages.findIndex(
      (error) => error.key === errorKey
    );
    if (errorIndex > -1) errorMessages.splice(errorIndex, 1);
  };

  public static addErrorMessage = (
    errorMessages: Array<any>,
    message: string,
    errorKey: string
  ) => {
    let errorMessage = errorMessages.find((error) => error.key === errorKey);
    if (errorMessage !== undefined)
      errorMessage.error = errorMessage.error + "; " + message;
    else
      errorMessages.push({
        key: errorKey,
        error: message,
      });
  };

  public static isResourceExist = (id?: any): boolean => {
    let result: any = true;
    result = id ? true : false;
    if (id) result = id !== strings.defaultNewAction;

    return result;
  };
}
