import * as React from "react";
import {
  IComboBoxOption,
} from "@fluentui/react";

export interface IMutableSetProps {
  model: any;
  onFieldChanged: (fieldName: string, fieldValue: any) => void;
  dropdownFieldName?: string;
}

export default class MutableSet<P = {}, S = {}> extends React.Component<P, S> {
  constructor(props: P) {
    super(props);
    this.onComboBoxChange = this.onComboBoxChange.bind(this);
    this.onTextFieldChange = this.onTextFieldChange.bind(this);
  }

  protected onComboBoxChange(fieldName: string, option?: IComboBoxOption) {
    if (option) this.handleFieldChange(fieldName, option.key);
  }

  protected onTextFieldChange(ev: any, newValue: string | undefined) {
    this.handleFieldChange(ev.target.name, newValue);
  }

  protected handleFieldChange = (fieldName: string, fieldValue: any) => {
    const { onFieldChanged } = this.props as unknown as IMutableSetProps;
    if (onFieldChanged) onFieldChanged(fieldName, fieldValue);
  };
}
