import componentHelper from "components/componentHelper";
import { IRowMenuItem } from "../../types";
import { getRowMenuGridStyleNames } from "./rowMenu.styleNames";

const { iconGridHover, iconDisabled } = getRowMenuGridStyleNames();

export default class RowMenuBuilder {
  private rowId: string = "";
  constructor(id: string) {
    this.rowId = id;
  }

  public getRenderedRowMenuItem = (rowMenuItem: IRowMenuItem): JSX.Element => {
    return componentHelper.renderRowMenuItem(
      rowMenuItem.iconName,
      rowMenuItem.disabled ? iconDisabled : iconGridHover,
      (ev?: any) =>
        this.onItemClicked(
          ev,
          rowMenuItem.action,
          rowMenuItem.disabled,
          rowMenuItem.row
        ),
      rowMenuItem.key || this.rowId + rowMenuItem.iconName,
      rowMenuItem.invisible,
      undefined,
      rowMenuItem.tooltipContent
    );
  };

  private onItemClicked = (
    ev?: any,
    action?: any,
    conditionDisabled?: boolean,
    row?: any
  ) => {
    ev!.stopPropagation();
    if (conditionDisabled) return;
    if (action) action(this.rowId, row);
  };
}
