const iconNames = {
  error401: "Error401",
  error404: "Error404",
  error500: "Error500",
  errorMessage: "error-message",
  hamburgerMenuIcon: "hamburger-menu-icon",
  optionGroups: "option-groups",
  navSideCheck: "nav-side-check",
  employees: "employees",
  gridMore: "grid-more",
  gridMoreDisabled: "grid-more-disabled",
  gridMoreExpand: "grid-more-expand",
  toggleCheck: "toggle-check",
  toggleUncheck: "toggle-uncheck",
  searchFilter: "search-filter",
  searchClear: "search-clear",
  firstPage: "first-page",
  lastPage: "last-page",
  prevPage: "prev-page",
  nextPage: "next-page",
  quickChart: "quick-chart",
  adminIcon: "admin-icon",
  adminAction: "admin-action",
  adminActionSlash: "admin-action-slash",
  reports: "reports",
  multipleDowloadButton: "multiple-dowload-button",
  multipleDowloadButtonDisabled: "multiple-dowload-button-disabled",
  gridDownload: "grid-download",
};

export default iconNames;
