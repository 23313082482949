import {
  IComboBoxOption,
} from "@fluentui/react";
import { storeHelper } from "utils";
import formHelper from "utils/formHelper";
import componentHelper from "../componentHelper";
import MutableSet, { IMutableSetProps } from "../MutableSet";
import {
  IMutableAndVerifiableSetStyleNames,
  getStyleNames,
} from "./mutableAndVerifiableSet.styleNames";

export interface IMutableAndVerifiableSetProps extends IMutableSetProps {
  errorMessages: Array<any>;
  onValidationResult: (fieldName: string, errorMessage: string) => void;
}

export interface IVerifiableComponent {
  validate(): void;
}

export class MutableAndVerifiableSet<P = {}, S = {}> extends MutableSet<P, S> {
  constructor(props: P) {
    super(props);
    this.onTextFieldChange = this.onTextFieldChange.bind(this);
    this.onComboBoxChange = this.onComboBoxChange.bind(this);
  }

  protected onTextFieldChange(ev: any, newValue: string | undefined) {
    super.onTextFieldChange(ev, newValue);
    this.removeValidationError(ev.target.name);
  }

  protected validateTextField = (
    rules: any,
    fieldName: string,
    fieldValue?: string
  ) => {
    const { onValidationResult } = this
      .props as unknown as IMutableAndVerifiableSetProps;
    if (onValidationResult)
      this.handleTextFieldValidation(
        onValidationResult,
        rules,
        fieldName,
        fieldValue
      );
  };

  protected validateList = (
    rules: any,
    fieldName: string,
    options?: Array<any>
  ) => {
    const rule = rules[fieldName];
    const { onValidationResult } = this
      .props as unknown as IMutableAndVerifiableSetProps;
    if (rule.required && (!options || options.length === 0))
      onValidationResult(fieldName, rule.message);
  };

  protected validateTextFields = (rules: any, fields: Array<any>) => {
    const { onValidationResult } = this
      .props as unknown as IMutableAndVerifiableSetProps;
    if (onValidationResult)
      fields.forEach((field: any) => {
        this.handleTextFieldValidation(
          onValidationResult,
          rules,
          field.name,
          field.value
        );
      });
  };

  protected onComboBoxChange(fieldName: string, option?: IComboBoxOption) {
    super.onComboBoxChange(fieldName, option);
    this.removeValidationError(fieldName);
  }

  protected removeValidationError = (fieldName?: string) => {
    const { errorMessages } = this
      .props as unknown as IMutableAndVerifiableSetProps;
    if (fieldName && errorMessages)
      formHelper.removeErrorMessage(errorMessages, fieldName);
  };

  protected onSubmitError(e: any, containerId?: string) {
    storeHelper.onSubmitError(e, containerId);
  }

  protected errorMessage = (
    errorKey: string,
    errorStyles?: any
  ): JSX.Element | undefined => {
    const { errorMessages } = this
      .props as unknown as IMutableAndVerifiableSetProps;
    return componentHelper.getElementErrorMessage(
      errorMessages,
      errorKey,
      errorStyles
    );
  };

  protected onsiErrorMessage = (errorKey: string): string | undefined => {
    const { errorMessages } = this
      .props as unknown as IMutableAndVerifiableSetProps;
    return formHelper.getErrorMessage(errorMessages, errorKey);
  };

  protected getDefaultStyles = (): IMutableAndVerifiableSetStyleNames => {
    return getStyleNames();
  };

  private handleTextFieldValidation = (
    validationAction: any,
    rules: any,
    fieldName: string,
    fieldValue?: string
  ) => {
    const rule = rules[fieldName];
    if (rule.required && !fieldValue) validationAction(fieldName, rule.message);
  };
}
