import { apiResources, http } from "services";
import {
  IReportEmployeeListItem,
  IReportListItem,
  ISearchCriteria,
  ISearchResultItem,
  ISelectedReport,
} from "types";

class ReportService {
  public async getReports(
    companyId: string,
    employeeId: string
  ): Promise<IReportListItem[]> {
    let result: any = await http.get(apiResources.reports.get, {
      params: {
        companyId: companyId,
        employeeId: employeeId,
      },
    });
    return result.data;
  }

  public async getYears(companyId: string): Promise<Array<number>> {
    let result: any = await http.get(apiResources.reports.getYears, {
      params: {
        companyId: companyId,
      },
    });
    return result.data;
  }
  public async getEmployees(
    companyId: string
  ): Promise<Array<IReportEmployeeListItem>> {
    let result: any = await http.get(apiResources.reports.getEmployees, {
      params: {
        companyId: companyId,
      },
    });
    return result.data;
  }

  public async downloadReport(
    companyId: string,
    employeeId: string,
    reportId: string
  ): Promise<any> {
    let result: any = await http.get(apiResources.reports.downloadReport, {
      responseType: "blob",
      params: {
        reportId: encodeURIComponent(reportId),
        companyId: companyId,
        employeeId: employeeId,
      },
    });
    return result;
  }

  public async searchReports(
    companyId: string,
    searchCriteria: ISearchCriteria
  ): Promise<Array<ISearchResultItem>> {
    let result: any = await http.post(
      apiResources.reports.searchReports,
      searchCriteria,
      {
        params: {
          companyId: companyId,
        },
      }
    );
    return result.data;
  }

  public async employerDownloadReports(
    companyId: string,
    employeeId: string,
    items: Array<ISelectedReport>
  ): Promise<any> {
    let result: any = await http.post(
      apiResources.reports.employerDownloadSelectedReports,
      items,
      {
        params: {
          companyId: companyId,
          employeeId: employeeId,
        },
        responseType: "blob",
      }
    );
    return result;
  }

  public async employeeDownloadReports(
    companyId: string,
    employeeId: string,
    selectedReports: Array<ISelectedReport>
  ): Promise<any> {
    let result: any = await http.post(
      apiResources.reports.employeeDownloadSelectedReports,
      selectedReports,
      {
        params: {
          companyId: companyId,
          employeeId: employeeId,
        },
        responseType: "blob",
      }
    );
    return result;
  }
}

export default new ReportService();
