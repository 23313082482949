import React from "react";
import { inject } from "mobx-react";
import {
  CommandBarButton,
  IOverflowSetItemProps,
  OverflowSet,
  nullRender,
  IContextualMenuItem,
  DirectionalHint,
  Stack,
} from "@fluentui/react";
import Stores from "stores";
import Loading from "components/Loading";
import { getStyleNames } from "./narrowNavMenu.styleNames";
import { IHistoryRouterProps } from "types";
import { authUtils } from "utils";
import { getColorStyleNames } from "styles/color.styleNames";
import routerHelper from "components/routerHelper";
import CompanyStore from "stores/companies/companyStore";
import formHelper from "utils/formHelper";
import { generatePath } from "react-router-dom";

export interface INarrowNavMenuState {
  loading: boolean;
  selectedMenuItem?: IContextualMenuItem;
  selectedKey?: string;
}
export interface INarrowNavMenuProps extends IHistoryRouterProps {
  companyStore?: CompanyStore;
}

@inject(Stores.RouterStore, Stores.CompanyStore)
export default class NarrowNavMenu extends React.Component<
  INarrowNavMenuProps,
  INarrowNavMenuState
> {
  private overflowMenuItems: IOverflowSetItemProps[] = [];
  private navigateOptions?: any;

  constructor(props: IHistoryRouterProps) {
    super(props);
    this.state = {
      loading: true,
      selectedMenuItem: undefined,
      selectedKey: undefined,
    };
  }

  async componentDidMount() {
    await this.updateOverflowMenuRouter(this.props.location).then(() => {
      this.loadNarrowNavMenu();
      this.setState({
        loading: false,
      });
      this.navigateOptions = routerHelper.getNavigateOptions(
        window.location.pathname
      );
    });
  }

  updateNarrowNavMenu = () => {
    this.setState(
      {
        selectedKey: undefined,
      },
      () => {
        this.loadNarrowNavMenu();
        this.forceUpdate();
      }
    );
  };

  render() {
    if (this.state.loading) return <Loading />;

    const { overflowSetWrapper, overflowSet } = getStyleNames();
    return (
      <Stack styles={overflowSetWrapper}>
        <OverflowSet
          vertical
          items={this.overflowMenuItems}
          onRenderOverflowButton={nullRender}
          onRenderItem={this.onRenderItem}
          styles={overflowSet}
        />
      </Stack>
    );
  }

  private loadNarrowNavMenu() {
    this.overflowMenuItems = [];
    this.props.routerStore?.OverflowRouters.forEach((overflowRouter: any) => {
      if (
        !overflowRouter.router.permission ||
        (overflowRouter.router.permission &&
          authUtils.isInRole(
            overflowRouter.router.permission,
            this.props.companyStore?.currentCompany?.roles!
          ))
      ) {
        const item = this.getOverflowMenuItem(overflowRouter);
        if (item) this.overflowMenuItems.push(item);
      }
    });
  }

  private onRenderItem = (item: IOverflowSetItemProps): JSX.Element => {
    const colors = getColorStyleNames();

    const currentRouter = this.props.routerStore?.currentRouter;
    const selectedKey = this.state.selectedKey
      ? this.state.selectedKey
      : currentRouter?.key;
    const { commandBarButton, overflowSetItem } = getStyleNames(
      selectedKey === item.key
    );
    const overflowItems = item.subMenuProps
      ? {
          items: item.subMenuProps,
          onItemClick: (e: any, item: any) => this.onSectionClick(e, item),
          directionalHint: DirectionalHint.rightTopEdge,
          beakWidth: 11,
          isBeakVisible: true,
          gapSpace: 2,
          calloutProps: {
            backgroundColor: colors.navMenuBackgroundColor,
          },
          styles: overflowSetItem,
        }
      : undefined;

    return (
      <CommandBarButton
        role="menuitem"
        styles={commandBarButton}
        onClick={(e) => this.onPageClick(e, item)}
        onMenuClick={(ev?: any, button?: any) => {
          this.setState({
            selectedKey: button?.data,
          });
        }}
        menuProps={overflowItems}
        menuIconProps={{ iconName: item.icon }}
        onRenderMenuIcon={
          item.imgClassName != null
            ? () => <span className={item.imgClassName}></span>
            : undefined
        }
        href={item.url}
        data={item.key}
      />
    );
  };

  private async updateOverflowMenuRouter(location: any) {
    const router = this.props.routerStore?.getRouter(location.pathname);
    this.props.routerStore?.updateRouters(router);
  }

  private getOverflowMenuItem = (overflowRouter: any): any => {
    if (overflowRouter.router.excludeInMenu === true) return undefined;
    let menuItems: IOverflowSetItemProps[] | undefined = undefined;
    if (overflowRouter.items && overflowRouter.items.length > 0) {
      menuItems = [];
      overflowRouter.items.forEach((overflowRouterItem: any) => {
        const menuItem = this.getContextualMenuItem(overflowRouterItem);
        if (menuItem) menuItems?.push(menuItem);
      });
    }

    return {
      key: overflowRouter.router.key,
      icon: overflowRouter.router.icon,
      name: overflowRouter.router.title,
      url: overflowRouter.router.path,
      subMenuProps: menuItems,
    };
  };

  private getContextualMenuItem = (
    overflowRouter: any
  ): IContextualMenuItem | undefined => {
    if (overflowRouter.router.excludeInMenu === true) return undefined;
    const { contextualMenuItem } = getStyleNames();

    return {
      key: overflowRouter.router.key,
      parent: overflowRouter.router.parent
        ? overflowRouter.router.parent
        : undefined,
      icon: overflowRouter.router.icon,
      name: overflowRouter.router.title,
      href: overflowRouter.router.path,
      isFocusable: overflowRouter.router.isFocusable,
      focusableSelector: overflowRouter.router.focusableSelector,
      itemProps: {
        styles: contextualMenuItem,
      },
    };
  };

  private onSectionClick = (ev?: any, item?: IContextualMenuItem) => {
    ev!.preventDefault();
    const router = this.props.routerStore?.getRouter(item?.href!);
    const currentRouter = this.props.routerStore?.currentRouter;
    let locationState = undefined;
    if (item?.isFocusable)
      this.props.routerStore?.setCurrentFocusableMenu(item?.focusableSelector);
    if (router.path !== currentRouter?.path) {
      if (item?.isFocusable) {
        locationState = {
          detail: item?.focusableSelector,
        };
        this.props.routerStore?.setCurrentFocusableMenu(item.focusableSelector);
      }

      if (locationState)
        this.navigateOptions = {
          state: locationState,
        };
      var path = this.getRedirectPath(item?.href!);

      this.props.navigate(path, this.navigateOptions);
    }
  };

  private onPageClick = (ev?: any, item?: IOverflowSetItemProps) => {
    ev!.preventDefault();
    this.setState({
      selectedKey: item?.key,
    });
    var path = this.getRedirectPath(item?.url);

    this.props.navigate(path, this.navigateOptions);
  };

  private getRedirectPath = (href: string): string | undefined => {
    var companyId = undefined;
    const matchParams = this.props.routerStore?.getMatchParams(
      this.props.location.pathname
    );
    if (matchParams && formHelper.isResourceExist(matchParams.id))
      companyId = matchParams.id;
    var path = companyId ? generatePath(href, { id: companyId }) : undefined;

    return path;
  };
}
