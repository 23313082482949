import React from "react";
import {
  ITextField,
  TextField,
  TooltipHost,
  Text,
  getId,
} from "@fluentui/react";
import { initializeComponentRef } from "@fluentui/react";
import { getTooltippedTextFieldStyles, ITooltippedTextFieldProps } from ".";
import { getColorStyleNames } from "../../styles/color.styleNames";
import { Guid } from "guid-typescript";

export class TooltippedTextField
  extends React.Component<ITooltippedTextFieldProps, {}>
  implements ITextField {
  private textField = React.createRef<ITextField>();
  private tooltipId = "";
  private textfieldId = "";

  constructor(props: ITooltippedTextFieldProps) {
    super(props);
    initializeComponentRef(this);
    this.tooltipId = getId(`tooltip-${Guid.raw()}`);
    this.textfieldId = getId(`textfield-${Guid.raw()}`);
  }

  public get selectionStart(): number | null {
    let start: number | null = null;
    if (this.textField.current) start = this.textField.current.selectionStart;

    return start;
  }

  public get selectionEnd(): number | null {
    let end: number | null = null;
    if (this.textField.current) end = this.textField.current.selectionEnd;

    return end;
  }

  public get value(): string | undefined {
    return this.textField.current?.value;
  }

  public focus(): void {
    this.textField.current?.focus();
  }

  public blur(): void {
    this.textField.current?.blur();
  }

  public select(): void {
    this.textField.current?.select();
  }

  public setSelectionStart(value: number): void {
    this.textField.current?.setSelectionStart(value);
  }

  public setSelectionEnd(value: number): void {
    this.textField.current?.setSelectionEnd(value);
  }

  public setSelectionRange(start: number, end: number): void {
    this.textField.current?.setSelectionRange(start, end);
  }

  public render(): JSX.Element {
    const {
      tooltipText,
      tooltipHost,
      tooltipCallout,
    } = getTooltippedTextFieldStyles(this.props.hasError);
    const { calloutBackgroundColor } = getColorStyleNames();

    return (
      <>
        <TooltipHost
          content={
            <Text theme={this.props.theme} styles={tooltipText}>
              {this.props.tooltipContentText}
            </Text>
          }
          id={this.tooltipId}
          calloutProps={{
            gapSpace: 0,
            backgroundColor: calloutBackgroundColor,
            styles: tooltipCallout,
            target: `#${this.textfieldId}`,
          }}
          styles={tooltipHost}
          theme={this.props.theme}
        >
          <TextField
            {...Object.assign({}, this.props)}
            componentRef={this.textField}
            aria-describedby={this.tooltipId}
            id={this.textfieldId}
          />
        </TooltipHost>
      </>
    );
  }
}
