import React from "react";
import {
  Icon,
  StackItem,
  IStackItemStyles,
  ITheme,
  Stack,
} from "@fluentui/react";
import {
  getErrorMessageStyles,
  getErrorMessageBeakStyles,
} from "./errorMessage.styleNames";
import { iconNames } from "utils";

export interface IErrorMessageProps {
  text?: string;
  containerStyles?: IStackItemStyles;
  theme?: ITheme;
}

export const ErrorMessage: React.FC<IErrorMessageProps> = (props) => (
  <>
    {props.text && (
      <StackItem className={"errorInnerContent"} styles={props.containerStyles}>
        <Stack styles={getErrorMessageBeakStyles}></Stack>
        <Stack>
          <StackItem styles={getErrorMessageStyles}>
            <Icon iconName={iconNames.errorMessage} />
            <span>{props.text}</span>
          </StackItem>
        </Stack>
      </StackItem>
    )}
  </>
);
