import React from "react";
import {
  Stack,
  StackItem,
  ActionButton,
  PrimaryButton,
} from "@fluentui/react";
import { getFooterStyleNames } from "./footer.styleNames";
import { getFormStyleNames } from "styles/form.styleNames";

export interface IOptionsConfiguratorFooterProps {
  onSubmit: () => void;
  onCancel: () => void;
  hasCancelAction: boolean;
  submitTitle?: string;
  cancelTitle?: string;
  submitIsDisabled?: boolean;
  hasCustomBorder?: boolean;
  hasBorder?: boolean;
}

export const OptionsConfiguratorFooter: React.FC<IOptionsConfiguratorFooterProps> =
  (props) => {
    const {
      containerStyles,
      containerItemsStyles,
      containerPrimaryButtonStyles,
      containerSecondaryButtonStyles,
      customBorder,
    } = getFooterStyleNames(props.hasCustomBorder, props.hasBorder);
    const { primaryButtonStyles, linkButtonStyles } = getFormStyleNames();
    return (
      <Stack>
        <Stack styles={containerStyles}>
          {props.hasCustomBorder && <div style={customBorder}>&nbsp;</div>}
          <Stack horizontal styles={containerItemsStyles}>
            {props.hasCancelAction && (
              <StackItem styles={containerSecondaryButtonStyles}>
                <ActionButton
                  onClick={props.onCancel}
                  styles={linkButtonStyles}
                >
                  {props.cancelTitle}
                </ActionButton>
              </StackItem>
            )}
            <StackItem styles={containerPrimaryButtonStyles}>
              <PrimaryButton
                styles={primaryButtonStyles}
                onClick={props.onSubmit}
                disabled={props.submitIsDisabled}
              >
                {props.submitTitle}
              </PrimaryButton>
            </StackItem>
          </Stack>
        </Stack>
      </Stack>
    );
  };
