import {
  IStackStyles,
  IStackItemStyles,
  IIconStyles,
  ICalloutContentStyles,
} from "@fluentui/react";

export interface IRowMenuGridStyleNames {
  stackStyles: IStackStyles;
  stackItemStyles: IStackItemStyles;
  iconGridMore: IIconStyles;
  iconGridHover: IIconStyles;
  iconDisabled: IIconStyles;
  callout: Partial<ICalloutContentStyles>;
}

export const getRowMenuGridStyleNames = (): IRowMenuGridStyleNames => ({
  stackStyles: {
    root: {
      display: "flex",
      justifyContent: "flex-end"
    },
  },
  stackItemStyles: {
    root: {
      width: "33%",
      marginLeft: "8px",
    },
  },
  iconGridMore: {
    root: {
      cursor: "pointer",
      //display: "flex",
      selectors: {
        svg: {
          height: 28,
          width: 28,
          border: "1px solid transparent",
          borderRadius: 56,
        },
        "svg:hover": {
          border: "1px solid #198BF5",
          borderRadius: 56,
        },
      },
    },
  },
  iconGridHover: {
    root: {
      cursor: "pointer",
      //display: "flex",
      selectors: {
        svg: {
          border: "1px solid transparent",
          borderRadius: 56,
          height: 30,
          width: 30,
        },
        "svg:hover": {
          borderRadius: 56,
        },
      },
    },
  },
  iconDisabled: {
    root: {
      opacity: "0.4",
      cursor: "not-allowed",
      display: "flex",
      selectors: {
        svg: {
          border: "1px solid transparent",
          borderRadius: 56,
          height: 30,
          width: 30,
        },
        "svg:hover": {
          borderRadius: 56,
        },
      },
    },
  },
  callout: {
    root: {
      boxShadow: "none"
    },
  },
});
