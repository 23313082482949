import { apiResources, http } from "services";
import { ICompanyListItem } from "types";

class CompanyService {
  public async getCompanies(): Promise<ICompanyListItem[]> {
    //for the cold start start set timeout 5 min
    let result: any = await http.get(apiResources.companies.list, {
      timeout: 300000
    });
    return result.data;
  }
}

export default new CompanyService();
