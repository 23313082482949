import { ILinkStyles, IStackItemStyles, IStackStyles } from "@fluentui/react";
import { getColorStyleNames } from "styles/color.styleNames";
import { getDefaultFonts } from "styles/fonts/fontDefault";

const { fontRegular } = getDefaultFonts();

export interface IFooterStyle {
  footerWrapper: IStackStyles;
  footerHorizontalStack: IStackStyles;
  footerContentWrapper: IStackStyles;
  footerLogoIcon: IStackItemStyles;
  footerText: IStackItemStyles;
  footerTechRequestWrapper: IStackItemStyles;
  footerTechRequestLink: ILinkStyles;
  footerLinkWrapper: IStackItemStyles;
  footerLink: ILinkStyles;
}

const colors = getColorStyleNames();

export const getStyles = (): IFooterStyle => ({
  footerWrapper: {
    root: {
      padding: "10px 0px",
      width: "100%",
      backgroundColor: colors.footerBackgroundColor,
    },
  },
  footerContentWrapper: {
    root: {
      display: "flex",
      justifyContent: "center",
      flexFlow: "unset",
      alignItems: "center",
      width: "100%",
      "@media(max-width: 600px)": {
        flexDirection: "column",
      },
    },
  },
  footerHorizontalStack: {
    root: {
      display: "flex",
      justifyContent: "center",
      flexFlow: "unset",
      alignItems: "center",
      width: "100%",
    },
  },
  footerLogoIcon: {
    root: {
      paddingRight: 5,
    },
  },

  footerText: {
    root: {
      fontFamily: fontRegular.fontFamily,
      color: "#1D3260",
      fontStyle: "normal",
      fontWeight: "300",
      fontSize: "13.2632px",
      lineHeight: "18px",
      letterSpacing: "0.236842px",
    },
  },
  footerTechRequestWrapper: {
    root: {
      position: "absolute",
      right: 15,
      "@media(max-width: 600px)": {
        position: "static",
        right: "auto",
        paddingTop: 7,
      },
    },
  },
  footerTechRequestLink: {
    root: {
      fontStyle: "normal",
      fontWeight: "400",
      color: "#198BF5",
      letterSpacing: "0.25px",
      selectors: {
        "&:hover": {
          color: "#106ebe",
        },
      },
    },
  },
  footerLinkWrapper: {
    root: {
      padding: "0px 8px 0px 8px",
    },
  },
  footerLink: {
    root: {
      fontSize: 12,
      fontStyle: "normal",
      fontWeight: "400",
      color: "#198BF5",
      letterSpacing: "0.25px",
      selectors: {
        "&:hover": {
          color: "#106ebe",
        },
      },
    },
  },
});
