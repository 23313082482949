import { action, makeObservable } from "mobx";
import reportService from "services/reports/reportsService";

import {
  IReportEmployeeListItem,
  IReportListItem,
  ISearchCriteria,
  ISearchResultItem,
  ISelectedReport,
} from "types";

export default class ReportStore {
  reportList: Array<IReportListItem> = [];
  searchResult: Array<ISearchResultItem> = [];
  employeesList: Array<IReportEmployeeListItem> = [];
  yearsList: Array<number> = [];

  constructor() {
    makeObservable(this);
  }

  @action
  async getReportList(companyId: string, employeeId: string) {
    const result = await reportService.getReports(companyId, employeeId);
    if (result) this.reportList = result;
  }

  @action
  async downloadReport(
    companyId: string,
    employeeId: string,
    id: string
  ): Promise<any> {
    const result = await reportService.downloadReport(
      companyId,
      employeeId,
      id
    );
    return Promise.resolve(result);
  }

  @action
  async employeeDownloadReports(
    companyId: string,
    employeeId: string,
    selectedReports: Array<ISelectedReport>
  ): Promise<any> {
    const result = await reportService.employeeDownloadReports(
      companyId,
      employeeId,
      selectedReports
    );
    return Promise.resolve(result);
  }

  @action
  async employerDownloadReports(
    companyId: string,
    employeeId: string,
    selectedReports: Array<ISelectedReport>
  ): Promise<any> {
    const result = await reportService.employerDownloadReports(
      companyId,
      employeeId,
      selectedReports
    );
    this.searchResult = this.searchResult.map((item) => {
      if (selectedReports.some((e) => e.reportId === item.reportId)) {
        item.isViewed = true;
        return item;
      }
      return item;
    });

    return Promise.resolve(result);
  }

  @action
  async searchReports(companyId: string, searchCriteria: ISearchCriteria) {
    const result = await reportService.searchReports(companyId, searchCriteria);
    if (result) this.searchResult = result;
  }

  @action
  async getEmployees(companyId: string) {
    const result = await reportService.getEmployees(companyId);
    if (result) this.employeesList = result;
  }

  @action
  async getYears(companyId: string) {
    const result = await reportService.getYears(companyId);
    if (result) this.yearsList = result;
  }

  @action
  clearSearchResult() {
    this.searchResult = [];
  }
}
