import {
  IButtonStyles,
  IDetailsHeaderStyles,
  IDetailsListStyles,
  IDetailsRowStyles,
} from "@fluentui/react";
import { getColorListStyleNames } from "styles/color.styleNames";

export interface IReportListStyle {
  detailsListStyles: Partial<IDetailsListStyles>;
  detailsListHeaderStyles: Partial<IDetailsHeaderStyles>;
  detailsListRowStyles: Partial<IDetailsRowStyles>;
  linkButtonStyles: IButtonStyles;
}

const colorsList = getColorListStyleNames();

export const getStyles = (): IReportListStyle => ({
  detailsListStyles: {
    root: {
      color: "#636363",
      boxSizing: "border-box",
      width: "calc(100% + 2px)",
    },
  },

  linkButtonStyles: {
    root: {
      background: "none",
      border: "none",
      padding: 0,
      color: "#198bf5",
      cursor: "pointer",
    },
    rootHovered: {
      background: "none",
      border: "none",
      padding: 0,
      color: "#198bf5",
      textDecoration: "underline",
      cursor: "pointer",
    },
    rootPressed: {
      background: "none",
      border: "none",
      padding: 0,
      color: "#106ebe",
      textDecoration: "underline",
      cursor: "pointer",
    },
    label: {
      fontStyle: "normal",
      fontWeight: "400",
    }
  },

  detailsListHeaderStyles: {
    root: {
      color: colorsList.headerColor,
      backgroundColor: colorsList.headerBackgroundColor,
      border: "1px solid #A7A7A7",
      boxSizing: "border-box",
      height: "fit-content",
      borderRadius: "3px 3px 0px 0px",
      padding: 0,
    },
  },

  detailsListRowStyles: {
    root: {
      display: "flex",
      alignItems: "center",
      borderBottom: "1px solid #A7A7A7",
      borderLeft: "1px solid #A7A7A7",
      borderRight: "1px solid #A7A7A7",
      color: "#2F3C42",
      selectors: {
        ":hover": {
          background: "rgba(38, 50, 56, 0.23)",
        },
      },
    },
    cell: {
      paddingBottom: "0px",
      paddingTop: "0px",
      display: "flex",
      alignItems: "center",
      color: "#2F3C42",
    },
  },
});
