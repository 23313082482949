export * from "./reports/reportStore";
export * from "./users/usersStore";
export * from "./appearance/appearanceStore";
export * from "./routing/routerStore";
export * from "./support/techSupportStore";
export * from "./companies/companyStore";
export default class Stores {
  static ReportStore: string = "reportStore";
  static UsersStore: string = "usersStore";
  static EmployeeStore: string = "employeeStore";
  static AppearanceStore: string = "appearanceStore";
  static RouterStore: string = "routerStore";
  static TechSupportStore: string = "techSupportStore";
  static CompanyStore: string = "companyStore";
}
