import { Stack, StackItem } from "@fluentui/react";
import { FC } from "react";
import { getStyles } from "./content.styleNames";

interface IContentProps {
  children: React.ReactNode;
}

const Content: FC<IContentProps> = ({ children }) => {
  const { contentWrapper } = getStyles();
  return (
    <Stack styles={contentWrapper}>
      <StackItem>{children}</StackItem>
    </Stack>
  );
};

export default Content;
