import AppearanceStore from "./appearance/appearanceStore";
import CompanyStore from "./companies/companyStore";
import EmployeeStore from "./employee/employeeStore";
import ReportStore from "./reports/reportStore";
import RouterStore from "./routing/routerStore";
import TechSupportStore from "./support/techSupportStore";
import UsersStore from "./users/usersStore";

export default function initializeStores() {
  const employeeStore = new EmployeeStore();
  const reportStore = new ReportStore();
  const usersStore = new UsersStore();
  const appearanceStore = new AppearanceStore();
  const routerStore = new RouterStore();
  const techSupportStore = new TechSupportStore();
  const companyStore = new CompanyStore();
  return {
    reportStore: reportStore,
    usersStore: usersStore,
    appearanceStore: appearanceStore,
    routerStore: routerStore,
    techSupportStore: techSupportStore,
    employeeStore: employeeStore,
    companyStore: companyStore
  };
}
