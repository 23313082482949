import { apiResources, http } from "services";
import { ITechSupportRequest } from "types";

class TechSupportService {
  public async SendTechSupportRequest(
    companyId: string,
    employeeId: string,
    request: ITechSupportRequest
  ): Promise<any> {
    let result = await http.post(apiResources.techSupport.send, {
      params: {
        companyId: companyId,
        employeeId: employeeId,
      },
      request,
    });
    return result.data;
  }
}

export default new TechSupportService();
