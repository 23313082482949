import * as React from "react";
import { Stack, SearchBox, ITheme } from "@fluentui/react";
import * as _ from "lodash";
import { getSearchFilterStyleNames } from "./searchFilter.styleNames";
import { iconNames } from "../../../utils";

export interface ISearchFilterProps {
  onFilterChange: (filterText?: string) => void;
  onFilterClear: () => void;
  searchboxPlaceholderText: string;
  theme?: ITheme;
}

export const SearchFilter: React.FC<ISearchFilterProps> = (props) => {
  const { searchbox, clearButton } = getSearchFilterStyleNames();
  const onFilterValueChange = (
    ev?: React.ChangeEvent<HTMLInputElement>,
    newValue?: string
  ) => {
    props.onFilterChange(newValue);
  };
  return (
    <Stack>
      <SearchBox
        placeholder={props.searchboxPlaceholderText}
        styles={searchbox}
        iconProps={{
          iconName: iconNames.searchFilter,
        }}
        clearButtonProps={{
          iconProps: {
            iconName: iconNames.searchClear,
          },
          styles: clearButton,
        }}
        data-lpignore="true"
        autoComplete="new-password"
        autoCorrect="off"
        autoCapitalize="none"
        spellCheck={false}
        onClear={props.onFilterClear}
        onEscape={props.onFilterClear}
        onChange={_.debounce(onFilterValueChange, 1000)}
        onSearch={(newValue: any) => props.onFilterChange(newValue)}
        theme={props.theme}
      />
    </Stack>
  );
};
