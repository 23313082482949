import { Stack, Text } from "@fluentui/react";
import { ICellRendererParams } from "ag-grid-community";
import React from "react";
import { strings } from "../../../utils";

export const BooleanCellRenderer: React.FC<ICellRendererParams> = (props) => {
  const convertedValue =
    props.value === null || props.value === undefined
      ? strings.dash
      : props.value
      ? strings.yes
      : strings.no;
  return (
    <Stack>
      <Text>{convertedValue}</Text>
    </Stack>
  );
};
