import React from "react";
import { inject } from "mobx-react";

import Stores from "stores";
import AppearanceStore from "stores/appearance/appearanceStore";
import AppRouteBuilder from "routing/AppRouteBuilder";
export interface IAppProps {
  appearanceStore?: AppearanceStore;
}

const App = inject(Stores.AppearanceStore)((props: IAppProps) => {
  props.appearanceStore?.initialize();

  return <AppRouteBuilder />;
});

export default App;
