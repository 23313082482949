import { toast } from "react-toastify";

export default class StoreHelper {
  public static onSubmitError = (
    e: any,
    containerId?: string,
    showOnlyErrors?: boolean
  ): boolean => {
    const customErrors = e && e.errors && e.errors.length > 0;
    if (customErrors) {
      const errorMessage = showOnlyErrors ? e.errors : e.errors.join(",");
      if (!showOnlyErrors) console.error(errorMessage);
      toast.error(errorMessage, {
        autoClose: false,
        closeButton: true,
        position: "top-center",
        containerId: containerId,
      })
    }
    return customErrors;
  };
}
