import { INumberCellRendererStyles } from ".";
import { getColorStyleNames } from "../../../styles/color.styleNames";
import { getMainPalette } from "../../../styles/mainPalette";

const defaultPalette = getMainPalette();
const { textColor, disabledLinkColor } = getColorStyleNames();

export const getNumberCellRendererStyles = (
  hasError?: boolean
): INumberCellRendererStyles => ({
  textfield: {
    root: {
      width: "100%",
      textAlign: 'right',
    },
    fieldGroup: [
      hasError && {
        borderColor: defaultPalette.red,
        borderWidth: 1,
        borderRadius: "3px",
        background: defaultPalette.white,
      },
      !hasError && {
        borderColor: disabledLinkColor,
        borderWidth: 1,
        borderRadius: "3px",
        background: defaultPalette.white,
      },
    ],
    field: {
      color: hasError ? defaultPalette.red : textColor,
      letterSpacing: "0.25px",
      fontStyle: "normal",
      fontWeight: hasError ? "bold" : "normal",
      fontSize: 14,
      lineHeight: 19,
      textAlign: "center",
    },
    errorMessage: {
      paddingTop: 0,
    },
  },
  text: {
    root: {
      color: hasError ? defaultPalette.red : textColor,
      letterSpacing: "0.25px",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: 14,
      lineHeight: 19,
    },
  },
  wrapper: {
    root: {
      border: `1px solid ${defaultPalette.red}`,
      borderRadius: "3px",
      background: defaultPalette.white,
      height: 32,
      padding: "5px 8px 0px 8px",
    },
  },
  wrapperError: {
    root: {
      display: "none",
    },
  },
});
