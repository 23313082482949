import { IButtonStyles } from "@fluentui/react";
import { IComboBoxStyles } from "@fluentui/react/lib/ComboBox";
import {
  getColorListStyleNames,
  getColorStyleNames,
} from "styles/color.styleNames";
import { getGenericStyleNames } from "styles/section.styleNames";

export interface IComboBoxStyleNames {
  comboBoxWrapperOnError: Partial<IComboBoxStyles>;
  formComboBox: Partial<IComboBoxStyles>;
  formCaretDownButtonStyles: Partial<IButtonStyles>;
}
const colors = getColorStyleNames();
const colorsList = getColorListStyleNames();

export const getStyleNames = (): IComboBoxStyleNames => ({
  comboBoxWrapperOnError: {
    root: {
      selectors: {
        "&::after": {
          borderColor: "#EF273C",
          borderWidth: 2,
        },
        "&:hover::after": {
          borderColor: "#a4262c !important",
        },
      },
    },
    label: getGenericStyleNames().labelStyle,
  },
  formComboBox: {
    root: {
      selectors: {
        "&::after": {
          border: "1px solid",
          boxSizing: "border-box",
          borderRadius: "3px",
          borderColor: colorsList.borderColor,
        },
        "&:focus-within::after": {
          border: "2px solid",
          boxSizing: "border-box",
          borderRadius: "3px",
          borderColor: colors.focusColor,
        },
      },
    },
  },
  formCaretDownButtonStyles: {
    root: {
      borderLeft: "1px solid",
      borderLeftColor: colorsList.borderColor,
    },
  },
});
