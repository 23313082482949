import { getColorListStyleNames, getColorStyleNames } from "styles/color.styleNames";

const colors = getColorStyleNames();
const colorsList = getColorListStyleNames();
export interface ICustomThemeStyle {
  backgroundColor: string;
}

export interface ICustomTheme {
  dark: ICustomThemeStyle;
  light: ICustomThemeStyle;
}

export const themes: ICustomTheme = {
  dark: {
    backgroundColor: colors.darkSpinnerColor,
  },
  light: {
    backgroundColor: colorsList.backgroundColor,
  },
};

export default colors;
