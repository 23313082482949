import {
  AuthenticationResult,
  PublicClientApplication,
} from "@azure/msal-browser";
import { b2cApplicationScopes, msalConfig } from "components/auth/authConfig";
import { IAccountClaims } from "types";

class TokenService {
  public getUserInformation = async (): Promise<AuthenticationResult> => {
    let app = new PublicClientApplication(msalConfig);
    await app.initialize();
    let account = app.getAllAccounts()[0];
    return app.acquireTokenSilent({
      scopes: ["openid", ...[b2cApplicationScopes]],
      account: account,
    });
  };

  public getAccountClaims = async (): Promise<IAccountClaims> => {
    let app = new PublicClientApplication(msalConfig);
    await app.initialize();
    let account = app.getAllAccounts()[0] as any;
    return account.idTokenClaims as IAccountClaims;
  };
}
export default new TokenService();
