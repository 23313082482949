import React from "react";
import {
  IComboBoxProps,
  ComboBox,
  IComboBox,
  IComboBoxOption,
  //VirtualizedComboBox,
} from "@fluentui/react/lib/ComboBox";
import { initializeComponentRef } from "@fluentui/utilities";
import { getStyleNames } from "./combobox.styleNames";
import { ErrorMessage } from "components/Model/Validation";

interface IOnsiComboBoxProps extends IComboBoxProps {
  isVirtualized: boolean;
  onsiErrorMessage?: JSX.Element | string | undefined;
}

export class OnsiComboBox
  extends React.Component<IOnsiComboBoxProps, {}>
  implements IComboBox
{
  private _comboBox = React.createRef<IComboBox>();

  constructor(props: IOnsiComboBoxProps) {
    super(props);

    initializeComponentRef(this);
  }

  public static defaultProps: IOnsiComboBoxProps = {
    isVirtualized: false,
    options: [],
  };

  public get selectedOptions(): IComboBoxOption[] {
    if (this._comboBox.current) {
      return this._comboBox.current.selectedOptions;
    }
    return [];
  }

  public dismissMenu(): void {
    if (this._comboBox.current) {
      return this._comboBox.current.dismissMenu();
    }
  }

  public focus(shouldOpenOnFocus?: boolean, useFocusAsync?: boolean) {
    if (this._comboBox.current) {
      this._comboBox.current.focus(shouldOpenOnFocus, useFocusAsync);
      return true;
    }

    return false;
  }

  public render(): JSX.Element {
    const { onsiErrorMessage } = this.props;
    let error: any = onsiErrorMessage;
    if (onsiErrorMessage && typeof onsiErrorMessage === "string") {
      error = <ErrorMessage text={onsiErrorMessage} />;
    }

    return (
      <>
        {!this.props.isVirtualized && (
          <ComboBox
            {...Object.assign(
              {},
              this.props,
              onsiErrorMessage
                ? { styles: getStyleNames().comboBoxWrapperOnError }
                : undefined
            )}
            componentRef={this._comboBox}
          />
        )}
        {/*TODO:*/}
        {/*this.props.isVirtualized && (
          <VirtualizedComboBox
            {...Object.assign(
              {},
              this.props,
              onsiErrorMessage
                ? { styles: getStyleNames().comboBoxWrapperOnError }
                : undefined
            )}
            componentRef={this._comboBox}
          />
            )*/}
        {error}
      </>
    );
  }
}
