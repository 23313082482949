import { Spinner, SpinnerSize, Stack } from "@fluentui/react";
import { ICustomThemeStyle } from "styles/themes/customTheme";
import { getStyleNames } from "./modalLoading.styleNames";

export interface IModalLoadingProps {
  theme?: ICustomThemeStyle;
}
const ModalLoading = (props: IModalLoadingProps) => {
   const { loadingWrapper, spinner } = getStyleNames(props.theme);
   return(
  <Stack styles={loadingWrapper}>
    <Spinner size={SpinnerSize.large} styles={spinner} />
  </Stack>)
};

export default ModalLoading;
