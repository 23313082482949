const AppConsts = {
  techSupportRequestReasons: [
    {
      key: "loginIssues",
      text: "Login/Account Issues",
    },
    {
      key: "viewingIssues",
      text: "Viewing/Downloading Report",
    },
    {
      key: "incorrectReportIssues",
      text: "Report is Incorrect",
    },
    {
      key: "otherIssues",
      text: "Other",
    },
  ],
};
export default AppConsts;
