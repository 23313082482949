import { PartialTheme } from "@fluentui/react";
import { getDefaultFonts } from "./fontDefault";
import { getColorStyleNames } from "../color.styleNames";
import { getMainPalette } from '../mainPalette';

const { fontRegular } = getDefaultFonts();
const { textColor } = getColorStyleNames();
const defaultPalette = getMainPalette();

const themeDefault: PartialTheme = {
  palette: defaultPalette,
  defaultFontStyle: {
    fontFamily: fontRegular.fontFamily,
    fontWeight: fontRegular.fontWeight,
    fontStyle: fontRegular.fontStyle,
    color: textColor
  },
};

export default themeDefault;
