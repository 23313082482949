import { IStackStyles, IStackItemStyles } from "@fluentui/react";

export interface IFooterStyleNames {
  containerStyles: IStackStyles;
  containerItemsStyles: IStackStyles;
  containerPrimaryButtonStyles: IStackItemStyles;
  containerSecondaryButtonStyles: IStackItemStyles;
  customBorder: React.CSSProperties;
}

export const getFooterStyleNames = (
  hasCustomBorder?: boolean,
  hasBorder?: boolean
): IFooterStyleNames => ({
  containerStyles: {
    root: {
      borderTop: hasCustomBorder || !hasBorder ? undefined : "1px solid #A7A7A7",
      alignItems: "flex-end",
      boxShadow: hasCustomBorder || !hasBorder
        ? undefined
        : "rgba(176, 190, 197, 0.32) 0px -4px 4px",
    },
  },
  containerItemsStyles: {
    root: {
      paddingRight: 29,
      paddingTop: 20,
      paddingBottom: 20,
    },
  },
  containerPrimaryButtonStyles: {
    root: {
      alignSelf: "center",
    },
  },
  containerSecondaryButtonStyles: {
    root: {
      paddingRight: 36,
    },
  },
  customBorder: {
    height: 4,
    width: "100%",
    borderBottom: "1px solid #A7A7A7",
    boxShadow: "rgba(176, 190, 197, 0.32) 0px 4px 4px",
  },
});
