import { ErrorMessage } from "./Validation";

export default class ComponentHelper {
  public static getElementErrorMessage = (
    errorMessages: Array<any>,
    errorKey: string,
    errorStyles?: any
  ): JSX.Element | undefined => {
    const errorMessage = errorMessages.find((error) => error.key === errorKey);
    return ComponentHelper.getWrappedElementErrorMessage(
      errorMessage ? errorMessage.error : undefined,
      errorStyles
    );
  };

  public static getWrappedElementErrorMessage = (
    error?: string,
    errorStyles?: any
  ): JSX.Element | undefined => {
    return error ? (
      <ErrorMessage text={error} containerStyles={errorStyles} />
    ) : undefined;
  };
}
