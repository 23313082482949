import React from "react";
import { Icon, ITheme, TooltipHost } from "@fluentui/react";
import { getSectionStyleNames } from "../../../styles/section.styleNames";

export interface ICellTooltipIconProps {
  tooltipContentText?: string;
  theme?: ITheme;
  iconName?: string;
  iconStyles?: any;
}

export const CellTooltipIcon: React.FC<ICellTooltipIconProps> = (props) => {
  const { fieldTooltipStyles } = getSectionStyleNames();

  return (
    <TooltipHost
      content={props.tooltipContentText}
      calloutProps={{ gapSpace: 0 }}
      theme={props.theme}
      styles={fieldTooltipStyles}
    >
      <Icon iconName={props.iconName} styles={props.iconStyles} />
    </TooltipHost>
  );
};
