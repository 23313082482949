const employeeStrings = {
  employees: "Employees",
  employee: "Employee",
  nameOrEmployeeId: "Name / EEID",
  hireDate: "Hire Date",
  termDate: "Term Date",
  email: "Email",
  scaAnniversary: "SCA Anniversary",
  searchboxPlaceholderText:
    "Type Name / EEID, Email, HireDate, TermDate, SCA Anniversary",
  employer: "User is employer",
  addEmployer: "Add employer role",
  removeEmployer: "Remove employer role",
  changingRoles: "Please wait while roles are changing...",
  employeeFringeBenefitStatements: "Employee Fringe Benefit Statements",
};
export default employeeStrings;
