import {
  IIconStyles,
  IStackStyles,
  ITextStyles,
  IStackItemStyles
} from "@fluentui/react";

export interface IExceptionStyleNames {
  exceptionWrapper: IStackStyles;
  exceptionIcon: Partial<IIconStyles>;
  exceptionText: ITextStyles;
  exceptionDescription: ITextStyles;
  exceptionTextWrapper: IStackItemStyles;
}

export const getStyleNames = (): IExceptionStyleNames => ({
  exceptionWrapper: {
    root: {
      marginTop: 100,
      textAlign: "center"
    }
  },
  exceptionIcon: {
    root: { height: "auto", width: "auto", marginBottom: 55 },
    imageContainer: {
      height: "auto",
      width: "auto",
      verticalAlign: "middle"
    }
  },
  exceptionText: {
    root: {
      color: "#636363",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: 32,
      lineHeight: 37
    }
  },
  exceptionDescription: {
    root: {
      color: "#636363",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: 20
    }
  },
  exceptionTextWrapper: { root: { marginBottom: 30 } }
});
