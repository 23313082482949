import { action, makeObservable } from "mobx";
import techSupportService from "services/support/techSupportService";
import { ITechSupportRequest } from "types";

export default class TechSupportStore {
  constructor() {
    makeObservable(this);
  }

  @action
  async sendTechSupportRequest(
    companyId: string,
    employeeId: string,
    request: ITechSupportRequest
  ) {
    try {
      const result = await techSupportService.SendTechSupportRequest(
        companyId,
        employeeId,
        request
      );
      return Promise.resolve(result);
    } catch (e: any) {
      return Promise.reject(e);
    }
  }
}
