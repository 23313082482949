import { MsalAuthenticationResult, useMsal } from "@azure/msal-react";
import AuthRedirect from "components/auth/AuthRedirect";
import { b2cPolicies } from "components/auth/policies";

const AuthError: React.FC<MsalAuthenticationResult> = ({ error }) => {
  const { instance } = useMsal();
  const authRedirect = new AuthRedirect(instance);
  if (error?.errorMessage.includes("AADB2C90091")) {
    authRedirect.RedirectTo(b2cPolicies.authorities.signUpSignIn);
  }

  return <p>An Error Occurred: {error ? error.errorCode : "unknown error"}</p>;
};

export default AuthError;
