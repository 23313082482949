import { action, makeObservable, observable } from "mobx";
import companyService from "services/companies/companyService";
import { ICompanyListItem } from "types";

export default class CompanyStore {
  companiesList: ICompanyListItem[] = [];
  originalCompany: ICompanyListItem = {};
  @observable currentCompany: ICompanyListItem = {};

  constructor() {
    makeObservable(this);
  }

  @action
  setCurrentCompany(id: any = undefined) {
    if (this.currentCompany?.id !== id)
      this.currentCompany = this.companiesList.find(
        (company) => company.id === id
      )!;
    if (this.originalCompany?.id !== id)
      this.originalCompany = this.companiesList.find(
        (company) => company.id === id
      )!;
  }
  
  @action
  setOriginalCompany(id: any = undefined) {
    this.originalCompany =  this.companiesList.find(
      (company) => company.id === id
    )!;
  }

  @action
  async getCompaniesList() {
    const result = await companyService.getCompanies();
    if (result) this.companiesList = result;
    if (result.length > 0) {
      this.originalCompany = this.companiesList[0];
      this.currentCompany = this.companiesList[0];
    }
  }
}
