import { Link, Stack, StackItem } from "@fluentui/react";
import { getStyles } from "./footer.styleNames";
import { onsiLogoIcon } from "images";
import TechSupportSetup from "scenes/Help/components/TechSupportSetup";
import { useRef } from "react";
import { footerStrings, techSupportStrings } from "utils";

const Footer = () => {
  const {
    footerWrapper,
    footerContentWrapper,
    footerHorizontalStack,
    footerLogoIcon,
    footerText,
    footerTechRequestWrapper,
    footerTechRequestLink,
    footerLinkWrapper,
    footerLink,
  } = getStyles();
  const techSupportSetup = useRef<TechSupportSetup>(null);
  return (
    <Stack styles={footerWrapper}>
      <Stack horizontal styles={footerContentWrapper}>
        <Stack>
          <Stack horizontal styles={footerHorizontalStack}>
            <StackItem styles={footerLogoIcon}>
              <img src={onsiLogoIcon} alt={footerStrings.onsiLogoIconAlt} />
            </StackItem>
            <StackItem styles={footerText}>
              &copy; {new Date().getFullYear()}
            </StackItem>
          </Stack>
          <Stack horizontal styles={footerHorizontalStack}>
            <StackItem styles={footerLinkWrapper}>
              <Link
                styles={footerLink}
                underline={true}
                href={footerStrings.termsOfUseLink}
                target="_blank"
              >
                {footerStrings.termsOfUseLinkLabel}
              </Link>
            </StackItem>
            <StackItem styles={footerLinkWrapper}>
              <Link
                styles={footerLink}
                underline={true}
                href={footerStrings.privacyStatementLink}
                target="_blank"
              >
                {footerStrings.privacyStatementLinkLabel}
              </Link>
            </StackItem>
          </Stack>
        </Stack>
        <StackItem styles={footerTechRequestWrapper}>
          <Link
            styles={footerTechRequestLink}
            underline={true}
            onClick={() => {
              const model = techSupportSetup.current?.createModel();
              techSupportSetup.current?.onInit(model);
              techSupportSetup.current?.onOpen();
            }}
          >
            {techSupportStrings.techSupportRequestLink}
          </Link>
          <TechSupportSetup ref={techSupportSetup} />
        </StackItem>
      </Stack>
    </Stack>
  );
};
export default Footer;
