import { IStackItemStyles, IStackStyles, ITextStyles } from "@fluentui/react";
import { getColorStyleNames } from "styles/color.styleNames";
import { getDefaultFonts } from "styles/fonts/fontDefault";

const { fontRegular } = getDefaultFonts();
export interface IHomePageStyle {
  pageWrapper: IStackStyles;
  titleHeaderStyle: IStackItemStyles;
  titleTextStyle: ITextStyles;
}

const colors = getColorStyleNames();

export const getCommonStyles = (): IHomePageStyle => ({
  pageWrapper: {
    root: {
      marginLeft: 36,
      marginRight: 36,
    },
  },
  titleHeaderStyle: {
    root: {
      paddingTop: 20,
      paddingBottom: 20,
    },
  },
  titleTextStyle: {
    root: {
      fontFamily: fontRegular.fontFamily,
      color: colors.textColor,
      fontFtyle: "normal",
      fontWeight: "normal",
      fontSize: "32px",
      lineHeight: "37px",
      marginLeft: -2,
    },
  },
});
