import { IStackStyles, ISpinnerStyles } from "@fluentui/react";
import { getColorStyleNames } from "styles/color.styleNames";
import { ICustomThemeStyle } from "styles/themes/customTheme";
export interface ILoadingStyleNames {
  loadingWrapper: IStackStyles;
  spinner: ISpinnerStyles;
}

const colors = getColorStyleNames();

export const getStyleNames = (
  theme: ICustomThemeStyle | undefined
): ILoadingStyleNames => ({
  loadingWrapper: {
    root: {
      position: "absolute",
      display: "flex",
      left: "0",
      top: "0",
      width: "100%",
      height: "100%",
      backgroundColor: theme?.backgroundColor || colors.backgroundColor,
      opacity: ".8",

      textAlign: "center",
      justifyContent: "center",
      zIndex: "4000",
    },
  },
  spinner: {
    circle: {
      height: 48,
      width: 48,
      borderWidth: 3,
    },
  },
});
