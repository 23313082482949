import { IB2cPolicy } from "types";

export const b2cPolicies: IB2cPolicy = {
  authorities: {
    signUpSignIn: `https://${process.env.REACT_APP_AZURE_B2C_TENANT_NAME}.b2clogin.com/${process.env.REACT_APP_AZURE_B2C_TENANT_NAME}.onmicrosoft.com/${process.env.REACT_APP_AZURE_B2C_LOGIN_FLOW}`,
    changePassword: `https://${process.env.REACT_APP_AZURE_B2C_TENANT_NAME}.b2clogin.com/${process.env.REACT_APP_AZURE_B2C_TENANT_NAME}.onmicrosoft.com/${process.env.REACT_APP_AZURE_B2C_CHANGE_PASSWORD_FLOW}`,
    changeEmail: `https://${process.env.REACT_APP_AZURE_B2C_TENANT_NAME}.b2clogin.com/${process.env.REACT_APP_AZURE_B2C_TENANT_NAME}.onmicrosoft.com/${process.env.REACT_APP_AZURE_B2C_CHANGE_SIGNIN_NAME_FLOW}`,
  },
  authorityDomain: `${process.env.REACT_APP_AZURE_B2C_TENANT_NAME}.b2clogin.com`,
};
