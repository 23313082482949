import { action, makeObservable } from "mobx";
import employeeService from "services/employees/employeeService";
import tokenService from "services/systems/tokenService";
import { IUser } from "types";
import { dateHelper } from "../../utils";
export default class UsersStore {
  currentUser: IUser = {};

  constructor() {
    makeObservable(this);
  }

  @action
  async initializeUser(companyId: string, employeeId: string) {
    var claims = await tokenService.getAccountClaims();
    const employeeInfo = await employeeService.getEmployeeInfo(
      companyId,
      employeeId
    );
    this.currentUser = {
      id: claims.sub,
      employeeId: employeeInfo.id,
      displayName: `${employeeInfo.lastName}, ${employeeInfo.firstName}`,
      email: claims.email,
      isLocal: !claims.idp,
      eeId: employeeInfo.eeId,
      anniversaryDate: dateHelper.toFormattedDateString(
        employeeInfo.anniversaryDate
      ),
      startDate: dateHelper.toFormattedDateString(employeeInfo.startDate),
      company: {
        id: employeeInfo.company?.id,
        displayName: employeeInfo.company?.displayName,
      },
    };
  }
}
