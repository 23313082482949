import { lazy } from "react";
import { iconNames, permissionNames, strings } from "utils";
import { IAppRouterItem } from "../../types";

export const appRouter: IAppRouterItem[] = [
  {
    key: "0001",
    path: "/",
    exact: true,
    name: "home",
    title: strings.home,
    overTitle: "Dashboard",
    icon: "Home",
    permission: permissionNames.Employee,
    component: lazy(() => import("scenes/Home")),
    isLayout: true,
    isPage: true,
    showInMenu: false,
    isNormalMenuItem: true,
  },
  {
    key: "1",
    path: "/:id/dashboard",
    name: "dashboard",
    title: "Dashboard",
    overTitle: "Dashboard",
    permission: permissionNames.Employee,
    icon: iconNames.optionGroups,
    showInMenu: true,
    isPage: true,
    component: lazy(() => import("scenes/Home")),
    isNormalMenuItem: true,
  },
];
