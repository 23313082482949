export const apiResources = {
  reports: {
    get: "Reports/List",
    downloadReport: "Reports/DownloadReport",
    employerDownloadSelectedReports: "Reports/EmployerDownloadSelectedReports",
    employeeDownloadSelectedReports: "Reports/EmployeeDownloadSelectedReports",
    getEmployees: "Reports/Employees",
    getYears: "Reports/Years",
    searchReports: "Reports/SearchReports",
  },
  techSupport: {
    send: "TechRequest",
  },
  employees: {
    get: "Employee",
    list: "Employee/List",
    assignRoles: "Employee/AssignRoles",
  },
  companies: {
    list: "Company/List",
  },
};
