import { validationTechSupportRequestStrings } from "utils";

const rules = {
  reason: {
    required: true,
    message: validationTechSupportRequestStrings.reasonRequired,
  },
  description: {
    required: true,
    message: validationTechSupportRequestStrings.descriptionRequired,
  },
};

export default rules;
