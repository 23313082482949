import Loading from "components/Loading";
import { inject } from "mobx-react";
import { Suspense } from "react";
import { RouteObject, useRoutes } from "react-router-dom";
import Stores from "stores";
import RouterStore from "stores/routing/routerStore";

export interface IRouteBuilderProps {
  routerStore?: RouterStore;
}

const RouteBuilder = inject(Stores.RouterStore)((props: IRouteBuilderProps) => {
  const routes: RouteObject[] =
    props.routerStore?.getAllRouters().map((route) => {
      const redirectUrl = window.location.pathname;
      if (route.path !== redirectUrl)
        props.routerStore?.setRedirectLocationPath(redirectUrl);

      const Component = route.component;
      return {
        path: route.path,
        element: <Component />,
      };
    }) ?? [];

  let router = useRoutes(routes);

  return <Suspense fallback={<Loading />}>{router}</Suspense>;
});

export default RouteBuilder;
