import { Stack, StackItem } from "@fluentui/react";
import { initializeIcons } from "@fluentui/react/lib/Icons";

import Footer from "./components/Footer";
import Header from "./components/Header";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../styles/reactToastify/toastify.customStyles.css";

import { getStyles } from "./layout.styleNames";
import React from "react";
import SiderMenu from "./components/SiderMenu";
import Stores from "stores";
import { inject } from "mobx-react";
import AppearanceStore from "stores/appearance/appearanceStore";
import CompanyStore from "stores/companies/companyStore";
import { IHistoryRouterProps } from "types";
import { Outlet } from "react-router-dom";
import Content from "./components/Content";
import UsersStore from "stores/users/usersStore";
import { autorun } from "mobx";
import { storeHelper } from "utils";

export interface IAppLayoutProps extends IHistoryRouterProps {
  appearanceStore?: AppearanceStore;
  companyStore?: CompanyStore;
  usersStore?: UsersStore;
}

export interface IAppLayoutState {
  collapsed: boolean;
}
@inject(
  Stores.RouterStore,
  Stores.AppearanceStore,
  Stores.CompanyStore,
  Stores.UsersStore
)
export default class AppLayout extends React.Component<
  IAppLayoutProps,
  IAppLayoutState
> {
  private siderMenu = React.createRef<SiderMenu>();
  private autorunDisposer: any = null;

  constructor(props: IAppLayoutProps) {
    super(props);
    this.state = {
      collapsed: false,
    };
  }
  async componentDidMount() {
    initializeIcons(undefined, { disableWarnings: true });
    this.autorunDisposer = autorun(async () => {
      let companyId = this.props.companyStore?.currentCompany?.id!;
      let employeeId = this.props.companyStore?.currentCompany?.employeeId!;

      await this.props.usersStore
        ?.initializeUser(companyId, employeeId)
        .catch((e: any) => {
          console.error("App.componentDidMount() failed");
          storeHelper.onSubmitError(e);
        });
      this.forceUpdate();
      this.handleSiderMenu();
    });
  }

  componentWillUnmount() {
    if (this.autorunDisposer) this.autorunDisposer();
  }

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  onCollapse = (collapsed: any) => {
    this.setState({ collapsed });
  };

  render() {
    const {
      layoutStyle,
      headerStyle,
      contentStyle,
      footerStyle,
      siderMenuWrapper,
      contentStackStyle,
    } = getStyles();
    const currentTheme = this.props.appearanceStore?.CurrentTheme;
    return (
      <Stack horizontal styles={layoutStyle}>
        <Stack styles={siderMenuWrapper}>
          <SiderMenu
            path={this.props.match.path}
            onCollapse={this.onCollapse}
            navigate={this.props.navigate}
            location={this.props.location}
            collapsed={this.state.collapsed}
            toggle={this.toggle}
            theme={currentTheme}
            ref={this.siderMenu}
          />
        </Stack>
        <Stack styles={contentStackStyle}>
          <StackItem styles={headerStyle}>
            <Header />
          </StackItem>
          <StackItem styles={contentStyle}>
            <StackItem>
              <Content>
                <Outlet />
              </Content>
            </StackItem>
            <ToastContainer
              position="top-right"
              autoClose={2000}
              hideProgressBar={false}
              newestOnTop
              closeOnClick
              draggable={false}
            />
          </StackItem>
          <StackItem styles={footerStyle}>
            <Footer />
          </StackItem>
        </Stack>
      </Stack>
    );
  }

  private handleSiderMenu() {
    this.siderMenu.current?.updateSiderWideNavMenu();
    this.siderMenu.current?.updateSiderNarrowNavMenu();
  }
}
