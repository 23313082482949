import { IStackStyles } from "@fluentui/react";
export interface IHomePageStyle {
  reportListWrapper: IStackStyles;
}

export const getStyles = (): IHomePageStyle => ({
  reportListWrapper: {
    root: {
      paddingTop: "33px",
      paddingBottom: "43px",
    },
  },
});
