import React from "react";
import { Text, Stack, StackItem, Icon, ITheme } from "@fluentui/react";
import { getStyleNames } from "./siderMenu.styleNames";
import WideNavMenu from "./WideNavMenu";
import { iconNames, strings } from "utils";
import NarrowNavMenu from "./NarrowNavMenu";

export interface ISiderMenuProps {
  path: any;
  collapsed: boolean;
  onCollapse: any;
  navigate: any;
  location: any;
  toggle?: any;
  theme?: ITheme;
}

export default class SiderMenu extends React.Component<ISiderMenuProps> {
  private wideNavMenu = React.createRef<WideNavMenu>();
  private narrowNavMenu = React.createRef<NarrowNavMenu>();

  updateSiderWideNavMenu() {
    this.wideNavMenu.current?.updateWideNavMenu();
  }

  updateSiderNarrowNavMenu() {
    this.narrowNavMenu.current?.updateNarrowNavMenu();
  }

  render() {
    const { collapsed, navigate, location, toggle } = this.props;
    const {
      hamburgerMenuIcon,
      hamburgerMenuIconWrapper,
      label,
      labelWrapper,
      labelSeparator,
    } = getStyleNames();
    return (
      <>
        <Stack>
          <StackItem>
            <Stack horizontal styles={hamburgerMenuIconWrapper}>
              <StackItem grow={1}>
                <Icon
                  iconName={iconNames.hamburgerMenuIcon}
                  onClick={toggle}
                  styles={hamburgerMenuIcon}
                />
              </StackItem>
              <StackItem grow={1} styles={labelWrapper}>
                {!collapsed && (
                  <Text styles={label} theme={this.props.theme}>
                    {strings.appLabel}
                  </Text>
                )}
              </StackItem>
            </Stack>
          </StackItem>
          <StackItem>
            <Stack styles={labelSeparator}></Stack>
          </StackItem>
          {!collapsed && (
            <WideNavMenu
              navigate={navigate}
              location={location}
              theme={this.props.theme}
              ref={this.wideNavMenu}
            />
          )}
          {collapsed && (
            <NarrowNavMenu
              navigate={navigate}
              location={location}
              ref={this.narrowNavMenu}
            />
          )}
        </Stack>
      </>
    );
  }
}
