import { IStackItemStyles, ITextStyles } from "@fluentui/react";

export interface IHeaderStyleNames {
  headerStyles: IStackItemStyles;
  headerTextStyles: ITextStyles;
}

export const getStyleNames = (): IHeaderStyleNames => ({
  headerStyles: {
    root: {
      lineHeight: 21,
      textAlign: "center",
      color: "#636363",
      paddingTop: 12,
      paddingBottom: 13,
      borderBottom: "1px solid #000000",
      width: "100%",
    },
  },
  headerTextStyles: {
    root: {
      fontWeight: "normal",
      fontSize: 18,
      fontStyle: "normal",
    }
  },
});
