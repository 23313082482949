import { Icon, Stack } from "@fluentui/react";
import React from "react";
import { iconNames } from "../../../utils";

const wrapperStyles = {
  root: {
    paddingTop: 5,
  },
};

export const RowMenuHeaderGrid: React.FC<any> = () => {
  return (
    <Stack styles={wrapperStyles}>
      <Icon iconName={iconNames.quickChart} />
    </Stack>
  );
};
