import {
  IComboBox,
  IComboBoxOption,
  Stack,
  StackItem,
  TextField,
} from "@fluentui/react";
import { OnsiComboBox } from "components/Layout/components/ComboBox";
import {
  IMutableAndVerifiableSetProps,
  MutableAndVerifiableSet,
} from "components/Model/MutableAndVerifiableSet";
import AppConsts from "lib/appconst";
import React from "react";
import { getTechSupportRequestStyleNames } from "scenes/Help/techSupportRequest.styleNames";
import rules from "scenes/Help/techSupportRequest.validation";
import { getTextFieldStyles } from "styles/section.styleNames";
import { techSupportStrings, validationTechSupportRequestStrings } from "utils";
import formHelper from "utils/formHelper";
export interface ITechSupportFormState {
  isChanged?: boolean;
  errorForReason?: string;
  errorForDescription?: string;
}
export default class TechSupportForm extends MutableAndVerifiableSet<
  IMutableAndVerifiableSetProps,
  ITechSupportFormState
> {
  private cbReason = React.createRef<IComboBox>();
  private reasonOptions: IComboBoxOption[] =
    AppConsts.techSupportRequestReasons;
  private readonly fieldReason: string = "reason";
  private readonly fieldDescription: string = "description";

  constructor(props: IMutableAndVerifiableSetProps) {
    super(props);
    this.state = {
      errorForReason: undefined,
    };
  }

  validate(): void {
    this.validateList(rules, this.fieldReason!, this.props.model.reason);
    this.setState({
      errorForReason: formHelper.getErrorMessage(
        this.props.errorMessages,
        this.fieldReason!
      ),
    });
    this.validateTextField(
      rules,
      this.fieldDescription!,
      this.props.model.description
    );
    if (
      this.props.model.description &&
      this.props.model.description.length < 20
    )
      formHelper.addErrorMessage(
        this.props.errorMessages,
        validationTechSupportRequestStrings.descriptionMinLength,
        this.fieldDescription!
      );
  }

  render() {
    const {
      formTechSupportRequestWrapper,
      formComboBoxWrapper,
      formComboBox,
      formDescriptonMultilineTextBoxWrapper,
      formCaretDownButtonStyles,
    } = getTechSupportRequestStyleNames();
    return (
      <Stack styles={formTechSupportRequestWrapper}>
        <StackItem styles={formComboBoxWrapper}>
          <OnsiComboBox
            label={formHelper.getLabelText(
              techSupportStrings.reasonLabel,
              true
            )}
            placeholder={techSupportStrings.selectOne}
            options={this.reasonOptions}
            onChange={this.onReasonChange}
            iconButtonProps={{
              iconProps: {
                iconName: "CaretSolidDown",
              },
            }}
            styles={formComboBox}
            caretDownButtonStyles={formCaretDownButtonStyles}
            onsiErrorMessage={this.state.errorForReason}
            componentRef={this.cbReason}
          />
        </StackItem>
        <StackItem styles={formDescriptonMultilineTextBoxWrapper}>
          <TextField
            label={formHelper.getLabelText(
              techSupportStrings.descriptionLabel,
              true
            )}
            styles={getTextFieldStyles}
            name={this.fieldDescription}
            value={this.props.model.description || ""}
            onChange={this.onTextFieldChange}
            errorMessage={this.errorMessage(this.fieldDescription)}
            multiline={true}
            autoAdjustHeight
            rows={10}
            resizable={false}
          />
        </StackItem>
      </Stack>
    );
  }

  getSelectedReason() {
    const selectedDropdownOptions = this.cbReason.current?.selectedOptions;
    const selectedDropdownOption =
      selectedDropdownOptions && selectedDropdownOptions.length > 0
        ? selectedDropdownOptions[0]
        : undefined;
    if (!selectedDropdownOption) return undefined;
    return selectedDropdownOption.text;
  }
  private onReasonChange = (ev: any, option?: IComboBoxOption): void => {
    this.onComboBoxChange(this.fieldReason, option);
    this.setState({
      ...this.state,
      errorForReason: option
        ? undefined
        : formHelper.getErrorMessage(
            this.props.errorMessages,
            this.fieldReason!
          ),
    });
  };
}
