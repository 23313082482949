import { IFontFace } from "@fluentui/react";

export const fontFamilyRoboto = "'Roboto', Arial, Helvetica, sans-serif";

export interface IFontDefault {
  fontRegular: IFontFace;
}

export const getDefaultFonts = (): IFontDefault => ({
  fontRegular: {
    fontFamily: fontFamilyRoboto,
    fontWeight: "400",
    fontStyle: "normal",
    src: "../../fonts/Roboto-Regular.ttf",
  }
});
