import { inject } from "mobx-react";
import { StackItem, Stack, Icon, Text } from "@fluentui/react";
import { getStyleNames } from "./exception.styleNames";
import Stores from "stores";
import { iconNames } from "utils";
import { useParams } from "react-router-dom";
import UsersStore from "stores/users/usersStore";

export interface IExceptionProps {
  usersStore?: UsersStore;
}

const Exception = inject(Stores.UsersStore)((props: IExceptionProps) => {
  const { errorCode } = useParams();
  const exception = [
    {
      errorCode: "404",
      errorImgName: iconNames.error404,
      errorTitle: "We are sorry, the page you requested cannot be found.",
      errorDescription:
        "The URL may be misspelled or the page you're looking for is no longer available.",
    },
    {
      errorCode: "401",
      errorImgName: iconNames.error401,
      errorTitle: "Not Authorized",
      errorDescription:
        "Sorry, but " +
        props.usersStore?.currentUser.displayName +
        " is not authorized to access this page.",
    },
    {
      errorCode: "403",
      errorImgName: iconNames.error401,
      errorTitle: "Forbidden",
      errorDescription:
        "Sorry, but " +
        props.usersStore?.currentUser.displayName +
        " is not authorized to access this page.",
    },
    {
      errorCode: "500",
      errorImgName: iconNames.error500,
      errorTitle: "Server Error",
      errorDescription: "Try refreshing the page.",
    },
  ];

  let error = exception.find((x) => x.errorCode === errorCode);

  if (error == null) {
    error = exception[3];
  }
  const {
    exceptionWrapper,
    exceptionIcon,
    exceptionText,
    exceptionDescription,
    exceptionTextWrapper,
  } = getStyleNames();

  return (
    <Stack styles={exceptionWrapper}>
      <Stack>
        <StackItem>
          <Icon iconName={error.errorImgName} styles={exceptionIcon} />
        </StackItem>
        <StackItem styles={exceptionTextWrapper}>
          <Text styles={exceptionText}>{error.errorTitle}</Text>
        </StackItem>
        <StackItem>
          <Text styles={exceptionDescription}>{error.errorDescription}</Text>
        </StackItem>
      </Stack>
    </Stack>
  );
});

export default Exception;
