import { lazy } from "react";
import Stores from "stores";
import { employeeStrings, iconNames, permissionNames } from "utils";
import { IAppRouterItem } from "../../types";

export const employeeRouter: IAppRouterItem[] = [
  {
    key: "2",
    path: "/:id/employees",
    title: employeeStrings.employees,
    name: employeeStrings.employees.toLowerCase(),
    permission: permissionNames.Employer,
    showInMenu: true,
    isExpanded: false,
    isPage: true,
    icon: iconNames.employees,
    store: Stores.EmployeeStore,
    component: lazy(() => import("scenes/Employees")),
    isNormalMenuItem: true,
  },
  {
    key: "3",
    path: "/:id/reports",
    title: "Reports",
    name: "reports",
    permission: permissionNames.Employer,
    showInMenu: true,
    isExpanded: false,
    isPage: true,
    icon: iconNames.reports,
    store: Stores.EmployeeStore,
    component: lazy(() => import("scenes/SearchReports")),
    isNormalMenuItem: true,
  },
];
