import { Stack, StackItem, Text } from "@fluentui/react";
import { IUser } from "types";
import { getStyles } from "./userInfo.styleNames";

type Props = {
  user: IUser;
};

const UserInfo = ({ user }: Props) => {
  const {
    userInfoStyle,
    textStyle,
    col1Style,
    col2Style,
    col2TextStyle,
    col2ValueTextStyle,
    colBoxStyle,
    rowBoxStyle,
  } = getStyles();

  return (
    <Stack horizontal styles={userInfoStyle}>
      <StackItem styles={col1Style}>
        <Stack>
          <StackItem styles={rowBoxStyle}>
            <Text styles={textStyle}>
              {user.displayName} — #{user.eeId}
            </Text>
          </StackItem>
          <StackItem>
            <Text styles={textStyle}>{user.email}</Text>
          </StackItem>
        </Stack>
      </StackItem>
      <StackItem styles={col2Style}>
        <Stack>
          <StackItem styles={rowBoxStyle}>
            <Stack horizontal>
              <StackItem styles={colBoxStyle}>
                <Text styles={col2TextStyle}>Hire Date</Text>
              </StackItem>
              <StackItem>
                <Text styles={col2ValueTextStyle}>{user.startDate}</Text>
              </StackItem>
            </Stack>
          </StackItem>
          <StackItem>
            <Stack horizontal>
              <StackItem styles={colBoxStyle}>
                <Text styles={col2TextStyle}>SCA Anniversary</Text>
              </StackItem>
              <StackItem>
                <Text styles={col2ValueTextStyle}>{user.anniversaryDate}</Text>
              </StackItem>
            </Stack>
          </StackItem>
        </Stack>
      </StackItem>
    </Stack>
  );
};

export default UserInfo;
