import AppLayout from "components/Layout";
import Loading from "components/Loading";
import { inject, observer } from "mobx-react";
import { useCallback, useEffect, useState } from "react";
import {
  generatePath,
  Route,
  Routes,
  useLocation,
  useMatch,
  useNavigate,
} from "react-router-dom";
import RouteBuilder from "routing/RouteBuilder";
import Home from "scenes/Home";
import { HttpService } from "services";
import Stores from "stores";
import CompanyStore from "stores/companies/companyStore";
import ReportStore from "stores/reports/reportStore";
import RouterStore from "stores/routing/routerStore";
import UsersStore from "stores/users/usersStore";
import { storeHelper } from "utils";
import formHelper from "utils/formHelper";

export interface IRouterBuilderProps {
  usersStore?: UsersStore;
  reportStore?: ReportStore;
  routerStore?: RouterStore;
  companyStore?: CompanyStore;
}

const Main = inject(
  Stores.UsersStore,
  Stores.ReportStore,
  Stores.RouterStore,
  Stores.CompanyStore
)(
  observer((props: IRouterBuilderProps) => {
    const navigate = useNavigate();
    const location = useLocation();
    const match = useMatch("/*");

    const [loading, setLoading] = useState(true);
    props.routerStore?.initRouters();
    HttpService.init(navigate);

    const init = useCallback(async () => {
      if (!props.companyStore?.currentCompany?.id) {
        await props.companyStore?.getCompaniesList().catch((e: any) => {
          console.error("App.componentDidMount() failed");
          storeHelper.onSubmitError(e);
        });
      }

      const matchParams = props.routerStore?.getMatchParams(location.pathname);
      if (
        matchParams &&
        formHelper.isResourceExist(matchParams.id) &&
        matchParams.id !== props.companyStore?.originalCompany?.id
      )
        props.companyStore?.setOriginalCompany(matchParams.id);

      const router = props.routerStore?.getRouter(location.pathname);
      props.routerStore?.updateCurrentRouter(router);
      if (
        !matchParams ||
        !formHelper.isResourceExist(matchParams.id) ||
        !props.routerStore?.isLocationAvailable(
          props.companyStore?.originalCompany?.roles!
        )
      ) {
        var path = props.companyStore?.originalCompany?.id
          ? generatePath(props.routerStore?.defaultRedirectPath!, {
              id: props.companyStore?.originalCompany?.id!,
            })
          : "/logout";
        navigate(path);
      } else {
        props.companyStore?.setCurrentCompany(
          props.companyStore?.originalCompany?.id
        );
      }

      if (!router) {
        navigate("/exception/404");
        return;
      }
      setLoading(false);
    }, [navigate, location, props]);

    useEffect(() => {
      init();
    }, [init]);

    if (loading) return <Loading />;
    return (
      <Routes>
        <Route
          path="/"
          element={
            <AppLayout navigate={navigate} location={location} match={match} />
          }
        >
          <Route index element={<Home />} />
          <Route path="/*" element={<RouteBuilder />} />
        </Route>
      </Routes>
    );
  })
);

export default Main;
