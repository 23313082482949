import { IStackStyles, ITextStyles } from "@fluentui/react";
import { getColorStyleNames } from "./color.styleNames";

export interface IOnsiGridStyleNames {
  cellTextLevel1: Partial<ITextStyles>;
  cellTextLevel2: Partial<ITextStyles>;
  cellTextRedLevel1: Partial<ITextStyles>;
  internalContainerWrapper: IStackStyles;
  rowMenuList: React.CSSProperties;
  rowMenuListItem: React.CSSProperties;
  rowMenuListItemPadding: React.CSSProperties;
  iconWrapper: IStackStyles;
}

const { textColor, gridCellTextColor } = getColorStyleNames();

export const getOnsiGridStyleNames = (
  marginBottom?: string
): IOnsiGridStyleNames => ({
  cellTextLevel1: {
    root: {
      color: gridCellTextColor,
      alignItems: "center",
      letterSpacing: "0.25px",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: 14,
      display: "flex",
      height: 19,
      lineHeight: 19,
      marginBottom: marginBottom ? marginBottom : 7,
    },
  },
  cellTextRedLevel1: {
    root: {
      color: "#EF273C",
      alignItems: "center",
      letterSpacing: "0.25px",
      fontSize: 14,
      display: "flex",
      fontWeight: "bold",
      height: 19,
      lineHeight: 19,
      marginBottom: marginBottom ? marginBottom : 7,
    },
  },
  cellTextLevel2: {
    root: {
      color: textColor,
      letterSpacing: "0.4px",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: 12,
      lineHeight: 16,
      marginBottom: marginBottom ? marginBottom : 7,
    },
  },
  internalContainerWrapper: {
    root: {
      display: "flex",
      justifyContent: "flex-end",
      paddingTop: 3,
    },
  },
  rowMenuList: { listStyleType: "none", margin: 0, padding: 0 },
  rowMenuListItem: { float: "left" },
  rowMenuListItemPadding: { float: "left", paddingRight: "10%" },
  iconWrapper: {
    root: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
      fontSize: 11,
    },
  },
});
