import { IAssignEmployeeRoleItem, IEmployee, IEmployeeListItem } from "types";
import { apiResources, http } from "services";

class EmployeeService {
  public async getEmployeeInfo(
    companyId: string,
    employeeId: string,
  ): Promise<IEmployee> {
    let result: any = await http.get(apiResources.employees.get, {
      params: {
        companyId: companyId,
        employeeId: employeeId,
      },
    });
    return result.data;
  }

  public async getEmployees(companyId: string): Promise<IEmployeeListItem[]> {
    let result: any = await http.get(apiResources.employees.list, {
      params: {
        companyId: companyId,
      },
    });
    return result.data;
  }

  public async assignEmployeeRoles(
    companyId: string,
    model: IAssignEmployeeRoleItem
  ): Promise<any> {
    let result: any = await http.put(apiResources.employees.assignRoles, model, {
      params: {
        companyId: companyId,
      },     
    });
    return result.data;
  }
}

export default new EmployeeService();
