import {
  IStackStyles,
} from "@fluentui/react";

export interface IMutableAndVerifiableSetStyleNames {
  bodyWrapper: IStackStyles;
  headerSectionMargin: IStackStyles;
}

export const getStyleNames = (): IMutableAndVerifiableSetStyleNames => ({
  bodyWrapper: {
    root: {
      background: "white",
      border: "0px",
      borderRadius: "3px",
      marginLeft: 22,
      marginRight: 22,
      padding: "28px 22px 28px 22px",
    },
  },
  headerSectionMargin: {
    root: {
      marginTop: 20,
    },
  },
});
