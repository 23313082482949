import { ITheme, Text } from "@fluentui/react";
import { ITooltipParams } from "ag-grid-community";
import React from "react";
import { getCellTooltipStyleNames } from "./cellTooltip.styleNames";

interface ICellTooltipProps extends ITooltipParams {
  cellClass: string;
  tooltipContentText?: (value?: any) => string;
  theme?: ITheme;
  hasError?: (value?: any) => boolean;
  color?: string;
}

export default class CellTooltip extends React.Component<ICellTooltipProps> {
  public static defaultProps: Partial<ICellTooltipProps> = {
    cellClass: "cell-tooltip",
    location: "cell",
  };

  getReactContainerClasses() {
    return [this.props.cellClass];
  }

  render() {
    let hasWrongValue = false;
    if (this.props.hasError)
      hasWrongValue = this.props.hasError(this.props.value);
    const { text } = getCellTooltipStyleNames();

    return hasWrongValue ? (
      <div
        className={this.props.cellClass}
        style={{
          backgroundColor: this.props.color,
        }}
      >
        <p>
          <Text theme={this.props.theme} styles={text}>
            {this.props.tooltipContentText
              ? this.props.tooltipContentText(this.props.value)
              : ""}
          </Text>
        </p>
      </div>
    ) : (
      <></>
    );
  }
}
