import React from "react";
import {
  Stack,
  StackItem,
  Dropdown,
  Text,
  IDropdownOption,
  IconButton,
  ITheme,
} from "@fluentui/react";
import { iconNames, strings } from "../../../utils";
import { getStyleNames } from "./pagination.styleNames";
import { IPaginatedResult } from "types";

export interface IPaginationGridProps {
  settings: IPaginatedResult;
  onPageChange: (page: number) => void;
  onPageSizeChange: (size: number) => void;
  onFirstPageClicked: () => void;
  onLastPageClicked: () => void;
  onPrevPageClicked: () => void;
  onNextPageClicked: () => void;
  theme?: ITheme;
}

export const PaginationGrid = React.memo<IPaginationGridProps> ((props) => {
  const {
    pagingWrapper,
    firstPageWrapper,
    nextPageWrapper,
    prevPageWrapper,
    lastPageWrapper,
    dropdownPage,
    pageCountWrapper,
    textPageCount,
    iconButton,
    resultWrapper,
    textResult,
    textShow,
  } = getStyleNames();
  const getPageListOptions = (totalPages: number): IDropdownOption[] => {
    const pageList = [];
    if (totalPages === 0)
      pageList.push({
        key: 0,
        text: "0",
      });
    else
      for (let page = 1; page <= totalPages; page++) {
        pageList.push({
          key: page,
          text: page.toString(),
        });
      }

    return pageList;
  };
  const onPageChanged = (
    event: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption
  ) => {
    props.onPageChange(Number(option?.key));
  };
  const onPageSizeChanged = (
    event: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption
  ) => {
    props.onPageSizeChange(Number(option?.key));
  };
  const getPageSizeOptions = (): IDropdownOption[] => {
    return [
      { key: 5, text: "5" },
      { key: 10, text: "10" },
      { key: 20, text: "20" },
      { key: 50, text: "50" },
      { key: 100, text: "100" },
      { key: -1, text: strings.all },
    ];
  };

  return (
    <Stack horizontal styles={pagingWrapper}>
      <StackItem styles={resultWrapper}>
        <Text styles={textResult} theme={props.theme}>{`${
          props.settings.totalItems ? props.settings.totalItems : 0
        } ${strings.results}`}</Text>
      </StackItem>
      <StackItem styles={resultWrapper}>
        <Text styles={textShow} theme={props.theme}>
          {strings.show}
        </Text>
      </StackItem>
      <StackItem>
        <Dropdown
          selectedKey={
            !props.settings.totalItems || props.settings.totalItems === 0
              ? 5
              : props.settings.pageSize
          }
          options={getPageSizeOptions()}
          onChange={onPageSizeChanged}
          styles={dropdownPage}
          disabled={!props.settings.totalItems || props.settings.totalItems < 5}
          theme={props.theme}
        />
      </StackItem>
      <StackItem styles={firstPageWrapper}>
        <IconButton
          iconProps={{
            iconName: iconNames.firstPage,
          }}
          onClick={props.onFirstPageClicked}
          disabled={props.settings.isFirst || props.settings.totalPages === 0}
          styles={iconButton}
        />
      </StackItem>
      <StackItem styles={prevPageWrapper}>
        <IconButton
          iconProps={{
            iconName: iconNames.prevPage,
          }}
          onClick={props.onPrevPageClicked}
          disabled={props.settings.isFirst || props.settings.totalPages === 0}
          styles={iconButton}
        />
      </StackItem>
      <StackItem>
        <Dropdown
          selectedKey={
            props.settings.totalPages === 0 ? 0 : props.settings.currentPage
          }
          options={getPageListOptions(props.settings.totalPages)}
          onChange={onPageChanged}
          styles={dropdownPage}
          disabled={props.settings.totalPages <= 1}
          theme={props.theme}
        />
      </StackItem>
      <StackItem styles={pageCountWrapper}>
        <Text styles={textPageCount} theme={props.theme}>{`of ${
          props.settings.totalPages ? props.settings.totalPages : 0
        }`}</Text>
      </StackItem>
      <StackItem styles={nextPageWrapper}>
        <IconButton
          iconProps={{
            iconName: iconNames.nextPage,
          }}
          onClick={props.onNextPageClicked}
          disabled={props.settings.isLast || props.settings.totalPages === 0}
          styles={iconButton}
        />
      </StackItem>
      <StackItem styles={lastPageWrapper}>
        <IconButton
          iconProps={{
            iconName: iconNames.lastPage,
          }}
          onClick={props.onLastPageClicked}
          disabled={props.settings.isLast || props.settings.totalPages === 0}
          styles={iconButton}
        />
      </StackItem>
    </Stack>
  );
});
