import {
  IButtonStyles,
  IComboBoxStyles,
  IStackItemStyles,
  IStackStyles,
} from "@fluentui/react";
import { getColorListStyleNames, getColorStyleNames } from "styles/color.styleNames";
import { getGenericStyleNames } from "styles/section.styleNames";

export interface ITechSupportRequestStyleNames {
  formTechSupportRequestWrapper: IStackStyles;
  formComboBoxWrapper: IStackItemStyles;
  formComboBox: Partial<IComboBoxStyles>;
  formDescriptonMultilineTextBoxWrapper: IStackItemStyles;
  formCaretDownButtonStyles: Partial<IButtonStyles>;
}

const colors = getColorStyleNames();
const colorsList = getColorListStyleNames();

export const getTechSupportRequestStyleNames =
  (): ITechSupportRequestStyleNames => ({
    formTechSupportRequestWrapper: {
      root: {
        marginLeft: 29,
        marginRight: 29,
        paddingTop: 50,
      },
    },
    formComboBoxWrapper: {
      root: {
        marginBottom: 33,
        maxWidth: 274
      },
    },
    formComboBox: {
      root: {
        selectors: {
           "&::after": {
             border: "1px solid",
             boxSizing: "border-box",
             borderRadius: "3px",
             borderColor: colorsList.borderColor,
           },
          "&:focus-within::after": {
            border: "2px solid",
            boxSizing: "border-box",
            borderRadius: "3px",
            borderColor: colors.focusColor,
          },
        },
      },
      input: {
        selectors: {
          "::placeholder": getGenericStyleNames().placeHolderStyle,
          ":-ms-input-placeholder": getGenericStyleNames().placeHolderStyle,
          "::-ms-input-placeholder": getGenericStyleNames().placeHolderStyle,
        },
      },
      label: getGenericStyleNames().labelStyle,
      optionsContainerWrapper: {
        width: 274
      }
    },
    formCaretDownButtonStyles: {
      root: {
        borderLeft: "1px solid",
        borderLeftColor: colorsList.borderColor,
      },
    },
    formDescriptonMultilineTextBoxWrapper: {
      root: {
        minWidth: "100%",
        width: 563,
        "@media(max-width: 600px)": {
          width: "50vw"
        },
      },
    },
  });
