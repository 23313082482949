import React from "react";
import { Stack } from "@fluentui/react";
import { OptionsConfiguratorHeader } from "../Options/OptionsConfigurator/Header";
import { OptionsConfiguratorFooter } from "../Options/OptionsConfigurator/Footer";
import formHelper from "utils/formHelper";
import { toast } from "react-toastify";
import { storeHelper, strings } from "utils";

export interface IModalSetupProps
{
  model?: any;
}

export interface IModalSetupState {
  modalIsOpen: boolean;
  loading: boolean;
  isChanged: boolean,
  model: any;
  errorMessages: Array<any>;
}

export abstract class ModalSetup<
  P extends IModalSetupProps,
  S extends IModalSetupState
> extends React.Component<P, S> {
  onInit = (initialModel?: any) => {
    const dataModel = Object.assign({}, initialModel);
    this.setState(
      {
        loading: true,
        modalIsOpen: true,
        isChanged: false,
        errorMessages: [],
        model: dataModel,
      },
      () =>
        this.setState({
          loading: false,
        })
    );
  };

  onOpen = () => {   
    this.setState({
      modalIsOpen: true,
      errorMessages: [],
    });
  };

  onSaved = () => {
    this.setState({ isChanged: false });
  };

  onClose = () => {
      this.setState({
        modalIsOpen: false,
      });
  };

  onRenderHeader = (headerTitle: string): JSX.Element => {
    return (
      <Stack>
        <OptionsConfiguratorHeader
          title={headerTitle}
        />
      </Stack>
    );
  };

  onRenderFooter = (
    submitAction: any,
    submitTitle: string,
    hasBorder?: boolean,
    hasCustomBorder?: boolean,
  ): JSX.Element => {
    return (
      <Stack>
        <OptionsConfiguratorFooter
          onSubmit={submitAction}
          onCancel={this.onClose}
          submitTitle={submitTitle}
          cancelTitle="Cancel"
          submitIsDisabled={
            !this.state.isChanged ||
            this.state.errorMessages.length > 0
          }
          hasBorder={hasBorder}
          hasCustomBorder={hasCustomBorder}
          hasCancelAction={true}
        />
      </Stack>
    );
  };

  onHandleModelFieldChange = (fieldName: string, fieldValue: any) => {
    const modalModel = this.state.model;
    const modelField = modalModel[fieldName];
    modalModel[fieldName] =
      typeof modelField === "number" ? Number(fieldValue) : fieldValue;
    this.setState({
      isChanged: true,
      model: modalModel,
    });
  };

  onHandleComponentValidationResult = (
    fieldName: string,
    errorMessage: string | undefined
  ) => {
    const { errorMessages } = this.state;
    const existingErrorMessage = formHelper.getErrorMessage(
      errorMessages,
      fieldName
    );
    if (errorMessage && !existingErrorMessage)
      errorMessages.push({
        key: fieldName,
        error: errorMessage,
      });
    this.setState({
      errorMessages: errorMessages,
    });
  };

  onSubmitError(e: any, containerId?: string, showOnlyErrors?: boolean) {
    storeHelper.onSubmitError(e, containerId, showOnlyErrors);
  }

  onValidateError(containerId?: string) {
    toast.error(strings.pleaseInputAllRequiredFields, {
      autoClose: 2000,
      position: "top-center",
      containerId: containerId,
    });
  }

  onSuccess(message: string, containerId?: string) {
    toast.success(message, {
      autoClose: 2000,
      position: "top-center",
      containerId: containerId,
    });
  }
}
