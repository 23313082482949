import tokenService from "services/systems/tokenService";
import { permissionNames } from "utils";

class AuthUtils {
  allRoles: { [name: string]: boolean } = {
    [permissionNames.Employee]: true,
    [permissionNames.Employer]: true,
  };

  public isInRole(roleName: string, grantedRoles: string[]) {
    if (!grantedRoles) return false;
    return (
      this.allRoles[roleName] !== undefined &&
      grantedRoles.indexOf(roleName) > -1
    );
  }

  public isInRoles(roleNames: string[], grantedRoles: string[]) {
    if (grantedRoles && roleNames.length > 0) {
      return roleNames.some(
        (roleName) =>
          this.allRoles[roleName] !== undefined &&
          grantedRoles.indexOf(roleName) >= -1
      );
    }
    return false;
  }
}

export default new AuthUtils();
