import {
  IButtonStyles,
  IOverflowSetStyles,
  IContextualMenuStyles,
  IStyle,
  IStackStyles,
  IContextualMenuItemStyles,
} from "@fluentui/react";
import { getColorStyleNames } from "styles/color.styleNames";
import { fontFamilyRoboto } from "styles/fonts/fontDefault";

export interface INarrowNavMenuStyleNames {
  commandBarButton: IButtonStyles;
  overflowSetWrapper: IStackStyles;
  overflowSet: IOverflowSetStyles;
  overflowSetItem: Partial<IContextualMenuStyles>;
  contextualMenuItem: Partial<IContextualMenuItemStyles>;
}

const baseColor: IStyle = {
  color: "white",
};

const baseRoot: IStyle = {
  backgroundColor: "#0a53ae",
  color: baseColor.color,
};

const colors = getColorStyleNames();

export const getStyleNames = (
  isMenuItemFocusable?: boolean
): INarrowNavMenuStyleNames => ({
  commandBarButton: {
    flexContainer: {
      selectors: {
        "&:hover": {
          backgroundColor: "#0A53AE !important",
        },
      },
    },
    menuIcon: {
      color: baseColor.color,
      height: "auto",
    },
    menuIconHovered: {
      selectors: {
        "&:hover": {
          backgroundColor: "#0A53AE !important",
        },
      },
    },
    root: {
      backgroundColor: "transparent",
      marginLeft: 5,
      color: baseColor.color,
      padding: 0,
      borderLeft: isMenuItemFocusable
        ? `2px solid ${colors.buttonTextColor}`
        : undefined,
    },
    rootHovered: baseRoot,
    rootPressed: baseRoot,
    rootExpanded: {
      backgroundColor: "#0a53ae",
      color: baseColor.color,
      borderLeft: "2px solid #FFFFFF",
    },
    rootFocused: {
      backgroundColor: "#0a53ae",
      color: baseColor.color,
      borderLeft: "2px solid #FFFFFF",
    },
  },
  overflowSetWrapper: {
    root: {
      maxHeight: "95vh",
      width: 50,
    },
  },
  overflowSet: {
    item: {
      lineHeight: 36,
      height: 36,
      display: "inline-block",
      alignSelf: "center",
      paddingRight: 7,
      selectors: {
        ".is-expanded": {
          backgroundColor: "#0A53AE",
        },
        ".ms-Button:hover": {
          backgroundColor: "#0A53AE",
        },
        ".is-expanded:hover": {
          backgroundColor: "#0A53AE !important",
        },
        "i svg path": {
          fill: "#FFFFFF",
        },
      },
    },
  },
  overflowSetItem: {
    root: {
      backgroundColor: colors.navMenuBackgroundColor,
    },
  },
  contextualMenuItem: {
    root: {
      fontFamily: fontFamilyRoboto,
      fontSize: 16,
      fontWeight: 500,
      lineHeight: 28,
      display: "flex",
      letterSpacing: "0.15px",
      color: colors.buttonTextColor,
      selectors: {
        "&:hover": {
          color: "white",
          backgroundColor: "#0A53AE",
        },
        "&:active": {
          color: "white",
          backgroundColor: "#0A53AE",
        },
      },
    },
  },
});
