import { IPublicClientApplication } from "@azure/msal-browser";
import { b2cApplicationScopes } from "./authConfig";

export default class AuthRedirect 
{
  private instance: IPublicClientApplication;

  constructor (props: IPublicClientApplication)
  {
    this.instance = props;
  }
  
  public RedirectTo(url: string) {
    this.instance
      .logoutRedirect({
        onRedirectNavigate: () => {
          return false;
        },
      })
      .then(() => {
        this.instance.loginRedirect({
          authority: url,
          scopes: ["openid", ...[b2cApplicationScopes]],
        });
      });
  }
  
} 